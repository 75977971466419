import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/authContext.js';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../axios.js';
import { useState } from 'react';
import { useRef } from 'react';

const AddressList = () => {
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const [datas,setDatas] = useState([]);
    const [filter,setFilter] = useState('');
    const [isOpen,setIsOpen] = useState(false);
    const [formType,setFormType] = useState('');
    const [input,setInput] = useState({
        id:'',
        type:'',
        receiverName:'',
        receiverPhone:'',
        address:'',
        provinsi:'',
        kabupaten:'',
        kecamatan:'',
        kelurahan:''
    });
    const receiverNameRef = useRef(null);
    const receiverPhoneRef = useRef(null);
    const addressRef = useRef(null);
    const typeRef = useRef(null);
    const [receiverNameLabelActive,setReceiverNameLabelActive] = useState('');
    const [receiverNameFilled,setReceiverNameFilled] = useState('');
    const [receiverPhoneLabelActive,setReceiverPhoneLabelActive] = useState('');
    const [receiverPhoneFilled,setReceiverPhoneFilled] = useState('');
    const [addressLabelActive,setAddressLabelActive] = useState('');
    const [addressFilled,setAddressFilled] = useState('');
    const [typeLabelActive,setTypeLabelActive] = useState('');
    const [typeFilled,setTypeFilled] = useState('');
    const [provinsi,setProvinsi] = useState([]);
    const [kabupaten,setKabupaten] = useState([]);
    const [kecamatan,setKecamatan] = useState([]);
    const [kelurahan,setKelurahan] = useState([]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('user-address/find-address?filter='+filter);
            setDatas(result.data.data);
        } catch (error) {
            setDatas([]);
        }
    }
    const initProvinsi = async() => {
        try {
            const result = await makeRequest.get('address/provinsi');
            setProvinsi(result.data.data);
        } catch (error) {
            setProvinsi([]);
        }
    }
    const initKabupaten = async() => {
        try {
            const result = await makeRequest.get('address/kabupaten/find-by-provinsi-id?provinsiId='+input.provinsi);
            setKabupaten(result.data.data);
        } catch (error) {
            setKabupaten([]);
        }
    }
    const initKecamatan = async() => {
        try {
            const result = await makeRequest.get('address/kecamatan/find-by-kabupaten-id?kabupatenId='+input.kabupaten);
            setKecamatan(result.data.data);
        } catch (error) {
            setKecamatan([]);
        }
    }
    const initKelurahan = async() => {
        try {
            const result = await makeRequest.get('address/kelurahan/find-by-kecamatan-id?kecamatanId='+input.kecamatan);
            setKelurahan(result.data.data);
        } catch (error) {
            setKelurahan([]);
        }
    }
    useEffect(()=>{
        initData();
        initProvinsi();
        if(!currentUser){
          navigate("/");
        }
    },[]);
    const popupForm = (data,type) => {
        let dto = {
            id:data.id,
            receiverName:data.addressReceiverName,
            receiverPhone:data.addressPhoneNumber,
            address:data.addressDetails,
            type:data.type,
            provinsi:data.provinsiId,
            kabupaten:data.kabupatenId,
            kecamatan:data.kecamatanId,
            kelurahan:data.kelurahanId
        }
        toggleInput(dto);
        setInput(dto);
        setIsOpen(true);
        setFormType(type);
    }
    const toggleInput = (data) => {
        setReceiverNameFilled(data.receiverName!=='' ? 'filled' : '');
        setReceiverNameLabelActive(data.receiverName!=='' ? 'active' : '');
        setReceiverPhoneFilled(data.receiverPhone!=='' ? 'filled' : '');
        setReceiverPhoneLabelActive(data.receiverPhone!=='' ? 'active' : '');
        setTypeFilled(data.type!=='' ? 'filled' : '');
        setTypeLabelActive(data.type!=='' ? 'active' : '');
        setAddressFilled(data.address!=='' ? 'filled' : '');
        setAddressLabelActive(data.address!=='' ? 'active' : '');
    }
    const setToMain = async(id) => {
        try {
            await makeRequest.put('user-address/set-to-main?id='+id);
            initData();
            alert('berhasil!');
        } catch (error) {
            alert(error.response.data.message);
        }
    }
    const deleteAddress = async(id) => {
        try {
            await makeRequest.delete('user-address?id='+id);
            alert('hapus alamat berhasil!');
            initData();
            setIsOpen(false);
        } catch (error) {
            alert(error.response.data.message);
        }
    }
    const searchFilter = async() => {
        try {
            const result = await makeRequest.get('user-address/find-address?filter='+filter);
            setDatas(result.data.data);
        } catch (error) {
            setDatas([]);
        }
    }
    const handleFocus = (e,type) => {
        e.preventDefault();
        if(type==='receiverName'){
            receiverNameRef.current.focus();
            setReceiverNameLabelActive('active');
        } else if(type==='receiverPhone'){
            receiverPhoneRef.current.focus();
            setReceiverPhoneLabelActive('active');
        } else if(type==='type'){
            typeRef.current.focus();
            setTypeLabelActive('active');
        } else {
            addressRef.current.focus();
            setAddressLabelActive('active');
        }
    }
    const handleBlur = (e,type) => {
        const {value} = e.target;
        if(type==='receiverName'){
            receiverNameRef.current.blur();
            if(value!==''){
                setReceiverNameFilled('filled')
            } else {
                setReceiverNameLabelActive('');
                setReceiverNameFilled('');
            }
        } else if(type==='receiverPhone'){
            receiverPhoneRef.current.blur();
            if(value!==''){
                setReceiverPhoneFilled('filled')
            } else {
                setReceiverPhoneLabelActive('');
                setReceiverPhoneFilled('');
            }
        } else if(type==='address'){
            addressRef.current.blur();
            if(value!==''){
                setAddressFilled('filled')
            } else {
                setAddressLabelActive('');
                setAddressFilled('');
            }
        } else {
            typeRef.current.blur();
            if(value!==''){
                setTypeFilled('filled')
            } else {
                setTypeLabelActive('');
                setTypeFilled('');
            }
        }
    }
    const handleChange = (e,type) => {
        e.preventDefault();
        if(type==='receiverName'){
            setInput((prev) => ({...prev, receiverName:e.target.value}));
        } else if(type==='receiverPhone'){
            setInput((prev) => ({...prev, receiverPhone:e.target.value}));
        } else if(type==='address'){
            setInput((prev) => ({...prev, address:e.target.value}));
        } else {
            setInput((prev) => ({...prev, type:e.target.value}));
        }
    }
    const handleSave = async() => {
        try {
            await makeRequest.post('user-address',input);
            alert('tambah alamat berhasil');
            initData();
            setIsOpen(false);
        } catch (error) {
            alert(error.response.data.message);
        }
    }
    const handleEdit = async() => {
        try {
            await makeRequest.put('user-address?id='+input.id,input);
            alert('ubah alamat berhasil');
            initData();
            setIsOpen(false);
        } catch (error) {
            alert(error.response.data.message);
        }
    }
    const provinsiChanged = async(e)=>{
        setInput((prev) => ({...prev, provinsi:e.target.value,kabupaten:'',kecamatan:'',kelurahan:''}));
    }
    const kabupatenChanged = (e)=>{
        setInput((prev) => ({...prev, kabupaten:e.target.value,kecamatan:'',kelurahan:''}));
    }
    const kecamatanChanged = (e)=>{
        setInput((prev) => ({...prev, kecamatan:e.target.value,kelurahan:''}));
    }
    const kelurahanChanged = (e)=>{
        setInput((prev) => ({...prev, kelurahan:e.target.value}));
    }
    const addressTypeChanged = async(e)=>{
        setInput((prev)=>({...prev,type:e.target.value}))
    }
    const check = (type) => {
        if(type==='kabupaten'){
            if(input.provinsi===''){
                alert('Mohon untuk pilih provinsi terlebih dahulu. Terima kasih!');
                return;
            }
        } else if(type==='kecamatan') {
            if(input.kabupaten===''){
                alert('Mohon untuk pilih kabupaten terlebih dahulu. Terima kasih!');
                return;
            }
        } else if(type==='kelurahan'){
            if(input.kecamatan===''){
                alert('Mohon untuk pilih kecamatan terlebih dahulu. Terima kasih!');
                return;
            }
        }
    }
    useEffect(()=>{
        initKabupaten();
    },[input.provinsi]);
    useEffect(()=>{
        initKecamatan();
    },[input.kabupaten]);
    useEffect(()=>{
        initKelurahan();
    },[input.kecamatan]);
  return (
    <>
        {currentUser &&
        <>
            <div className="section-profile profile-wrapper">
                <div className="container">
                    <div className="profile-title-page">Daftar Alamat</div>

                    <div className="fz20 semibold black">Alamat Saya</div>
                    <div className="gray2 fz14">Kelola informasi profil Anda untuk mengontrol, melindungi dan mengamankan akun.</div>

                    <div className="content-profile">
                        <div className="bordered-box bordered-adreess">
                            <div className="search-address-wrapper">
                                <input type="text" name="address" onChange={(e)=>setFilter(e.target.value)} placeholder="cari alamat atau nama penerima" onKeyDown={(e)=>{
                                    if(e.key==='Enter'){
                                        searchFilter();
                                    }
                                }}/>
                                <div className="button-search" onClick={searchFilter}>
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                            <div className="button-address-wrapper">
                                <button type="button" className="btnNine-address active">Semua Alamat</button>
                                <button type="button" className="btnNine-address js-popup" data-popup="add-address" onClick={(e)=>popupForm({id:'',addressReceiverName:'',type:'',addressPhoneNumber:'',addressDetails:'',provinsiId:'',kabupatenId:'',kecamatanId:'',kelurahanId:''},'add')}>Tambah Alamat</button>
                            </div>
                            {datas.map(data=>{
                                return (
                                    <div className={data.isMain ? "bordered-box address-list-choice active":"bordered-box address-list-choice"} key={data.id}>
                                        <div className="btn-choice-wrapper">
                                            <i className="fa fa-check"></i>
                                            {!data.isMain && <a role='button' onClick={(e)=>setToMain(data.id)} className="btnNine btnNine-default js-pick-address">Pilih</a>}
                                        </div>
                                        <div className="flex" style={{alignItems:"center"}}>
                                            <div className="address-name"><span>{data.addressReceiverName}</span> ({data.type})</div>
                                            {data.isMain && <div className="address-main">Utama</div>}
                                        </div>
                                        <div className="address-phone">{data.addressPhoneNumber}</div>
                                        <p className="address-desc">{data.addressDetails}</p>
                                        <div className="clearfix address-utility-wrapper">
                                            <div className="pull-right btn-utility-address js-popup" onClick={(e)=>popupForm(data,'edit')} data-popup="edit-address">Ubah Alamat</div>
                                            <div className="pull-right btn-utility-address" onClick={(e)=>deleteAddress(data.id)}>Hapus</div>
                                            <div onClick={(e)=>setToMain(data.id)} className="pull-right btn-utility-address js-set-main" style={{display:!data.isMain ? 'block':'none'}}>Jadikan Alamat Utama &amp; Pilih</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className={isOpen ? "overlay-wrapper open" : "overlay-wrapper"} style={{display: isOpen ? 'block' : 'none'}}>
                <div className={isOpen ? "popup-wrapper popup-big popup-current" : "popup-wrapper popup-big"}>
                    <div className="middle">
                        <div className="inline-space text-left mb1">
                            {formType === 'add' && <div className="filter-title fz18 bold">Tambah Alamat</div>}
                            {formType === 'edit' && <div className="filter-title fz18 bold">Ubah Alamat</div>}
                        </div>
                        <div className="form-group mb10px">
                            <div className="mb0">
                                <input value={input.receiverName} ref={receiverNameRef} onClick={(e) => handleFocus(e,'receiverName')} onBlur={(e) => handleBlur(e,'receiverName')} onChange={(e) => handleChange(e,'receiverName')} type="text" name="Recipient" id="recipient" className="form-control login-input" required/>
                                <label className={`${receiverNameLabelActive} ${receiverNameFilled}`} htmlFor='recipient'>Nama Penerima</label>
                            </div>
                        </div>
                        <div className="mb10px">
                            <select id='kabupaten' onChange={(e)=>addressTypeChanged(e)} value={input.type} className='dropdown-custom'>
                                <option value=''>--Pilih Tipe Alamat--</option>
                                <option value='Rumah'>Rumah</option>
                                <option value='Kantor'>Kantor</option>
                            </select>
                        </div>
                        <div className="form-group mb10px">
                            <div className="mb0">
                                <input value={input.receiverPhone} ref={receiverPhoneRef} onClick={(e) => handleFocus(e,'receiverPhone')} onBlur={(e) => handleBlur(e,'receiverPhone')} onChange={(e) => handleChange(e,'receiverPhone')} type="text" name="Phone" id="phone" className="form-control login-input" required/>
                                <label className={`${receiverPhoneLabelActive} ${receiverPhoneFilled}`} htmlFor='phone'>Telepon Penerima</label>
                            </div>
                        </div>
                        <div className="mb10px">
                            <select id='provinsi' onChange={(e)=>provinsiChanged(e)} value={input.provinsi} className='dropdown-custom'>
                                <option value=''>--Pilih Provinsi--</option>
                                {provinsi.length>0 && provinsi.map(prov=>(
                                    <option key={prov.id} value={prov.id}>{prov.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb10px">
                            <select id='kabupaten' onChange={(e)=>kabupatenChanged(e)} onClick={()=>check('kabupaten')} value={input.kabupaten} className='dropdown-custom'>
                                <option value=''>--Pilih Kabupaten--</option>
                                {kabupaten.length>0 && kabupaten.map(kab=>(
                                    <option key={kab.id} value={kab.id}>{kab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb10px">
                            <select id='kecamatan' onChange={(e)=>kecamatanChanged(e)} onClick={()=>check('kecamatan')} value={input.kecamatan} className='dropdown-custom'>
                                <option value=''>--Pilih Kecamatan--</option>
                                {kecamatan.length>0 && kecamatan.map(kec=>(
                                    <option key={kec.id} value={kec.id}>{kec.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb10px">
                            <select id='kelurahan' onChange={(e)=>kelurahanChanged(e)} onClick={()=>check('kelurahan')} value={input.kelurahan} className='dropdown-custom'>
                                <option value=''>--Pilih Kelurahan--</option>
                                {kelurahan.length>0 && kelurahan.map(kel=>(
                                    <option key={kel.id} value={kel.id}>{kel.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb2">
                            <div className="mb0" style={{height:"80px"}}>
                                <textarea value={input.address} ref={addressRef} onClick={(e) => handleFocus(e,'address')} onBlur={(e) => handleBlur(e,'address')} onChange={(e) => handleChange(e,'address')} className="form-control login-input" id="address" style={{height:"80px",minHeight:"80px"}}></textarea>
                                <label className={`${addressLabelActive} ${addressFilled}`} htmlFor='address'>Alamat</label>
                            </div>
                        </div>
                        <div className="clearfix">
                            <div className="pull-right">
                                <a role='button' onClick={()=>setIsOpen(false)} className="btnNine btnNine-secondary js-close-popup">Tutup</a>
                                {formType==='add' && <a role='button' onClick={handleSave} className="btnNine btnNine-default">Tambah</a>}
                                {formType==='edit' && <a role='button' onClick={handleEdit} className="btnNine btnNine-default">Ubah Alamat</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
    </>
  )
}

export default AddressList