import { Link } from "react-router-dom"
import logoFooterImg from '../img/logo-footer.svg';
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { makeRequest } from "../axios";

const Footer = () => {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(()=> {
        gsap.to('.footer',{
            scrollTrigger: {
                trigger: '.footer',
            },
            duration: 1.5,
            opacity: 1,
            ease: "power4.out)"
        }, );
    });
    const onclickSocial = async(type) => {
        try {
            const param = await makeRequest.get('params/find-by-name?name='+type);
            if(type!=='email'){
                window.open(param.data.data.value);
            } else {
                window.location.href = 'mailto:'+param.data.data.value;
            }
        } catch (error) {
            alert('failed')
        }
    }
  return (
    <footer className="footer">
        <div className="footer-wrapper">
            <div className="inner-footer">
                <div className="subfooter">
                    <Link to="/">
                        <img src={logoFooterImg} className="logo-footer" alt="logoFooter" />
                    </Link>
                </div>
                <div className="subfooter">
                    <ul className="menu-footer">
                        <li>
                            <Link to="/garansi"><span>Garansi</span></Link>
                        </li>
                        <li>
                            <Link to="/katalog"><span>Katalog</span></Link>
                        </li>
                        <li>
                            <Link to="/berita"><span>Promo</span></Link>
                        </li>
                        <li>
                            <Link to="/berita"><span>Event</span></Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy"><span>Privacy Policy</span></Link>
                        </li>
                        <li>
                        <   Link to="/legal-info"><span>Legal Info</span></Link>
                        </li>
                        <li>
                            <Link to="/tentang-kami"><span>Tentang Kami</span></Link>
                        </li>
                        <li>
                            <Link to="/kontak">Kontak</Link>
                        </li>
                    </ul>
                </div>
                <div className="subfooter">
                    <div className="social-wrapper">
                        <svg viewBox="0 0 1080 1080" onClick={()=>onclickSocial('email')}><path className="cls-1" d="M913.12,138H166.88C74.88,138,0,208.63,0,295.48v489C0,871.37,74.88,942,166.88,942H913.12c92,0,166.88-70.66,166.88-157.51v-489C1080,208.63,1005.12,138,913.12,138ZM605.06,547.91c-16.4,17.47-40.11,27.5-65.06,27.5s-48.65-10-65-27.5L182.62,236.71H897.41Zm-501.83-248,295,314c36,38.22,87.65,60.15,141.82,60.15S645.86,652.17,681.82,614L976.75,300V784.52c0,32.38-28.53,58.77-63.62,58.77H166.88c-35.12,0-63.65-26.38-63.65-58.77Z"/></svg>
                        <svg viewBox="0 0 1080 1080" onClick={()=>onclickSocial('instagram')}><path className="cls-1" d="M0,282.54c5-20.31,8.22-41.26,15.26-60.83C57.4,104.65,138.37,31.16,261.49,7.08,284,2.68,307.3,1,330.24.9,471.31.35,612.41,2.45,753.44.09c134.14-2.24,258.09,83.36,304.24,203.25,13.92,36.17,22.71,71.68,22.3,111.06-1.55,149.3-.58,298.63-.63,448-.06,158.33-118.86,293.91-276.09,314.89a291.71,291.71,0,0,1-38.17,2.33c-149.32.16-298.65-.81-448,.37C182.42,1081,43.46,985.1,7.54,832.31,4.83,820.75,2.5,809.1,0,797.49Q0,540,0,282.54ZM963.12,541c0-73.43.36-146.86-.14-220.29-.29-42.22-11.8-81.47-36.64-116C882.47,143.82,821.85,116.84,747.6,117c-139,.32-278,0-417.06.21-14,0-28.19.39-41.91,2.81-102.34,18.07-171.63,99.56-172,203.44-.56,144.79-.23,289.59-.06,434.39,0,29.57,5.29,58.45,18.34,85.08C172.79,920.33,235.07,962,321.48,962.79c144.37,1.25,288.76.67,433.15.25a225,225,0,0,0,52.57-6.3c93.21-22.92,155.67-103.15,155.89-199.13C963.25,685.41,963.12,613.22,963.12,541Z"/><path className="cls-1" d="M839.09,540.86c-.24,164.8-135.46,299-300.78,298.42-164-.53-298.41-136-297.84-300.32C241,374.92,376.91,240.15,540.83,241,705.63,241.9,839.33,376.31,839.09,540.86Zm-481.49-.32c-.13,100.26,80.74,181.32,181.56,182,100,.67,182.89-81.68,183.22-182.12.33-100.18-82-182.16-182.88-182C437.3,358.52,357.73,438.19,357.6,540.53Z"/><path className="cls-1" d="M755,257.3c0-37.66,31.06-68.69,68.28-68.24,37,.45,67.57,31.05,67.78,67.86a68,68,0,0,1-68,68.47C784.95,325.5,755,295.53,755,257.3Z"/></svg>
                        <svg viewBox="0 0 1080 1080" onClick={()=>onclickSocial('facebook')}><path className="cls-1" d="M1080,314.38c.41-39.38-8.38-74.9-22.3-111.07C1011.53,83.41,887.58-2.2,753.44,0c-141,2.36-282.13.25-423.2.8C307.3.93,284,2.63,261.49,7,138.37,31.11,57.4,104.61,15.26,221.68,8.22,241.25,5,262.2,0,282.51v515c2.51,11.61,4.83,23.26,7.54,34.82C43.46,985.14,182.42,1081.05,317.13,1080c149.31-1.17,298.64-.21,448-.37a291.71,291.71,0,0,0,38.17-2.33c157.23-21,276-156.57,276.09-314.92C1079.4,613,1078.44,463.7,1080,314.38ZM963.09,757.62c-.22,96-62.67,176.23-155.89,199.15a225,225,0,0,1-52.57,6.31q-102.57.3-205.15.49V652.83H661l21.22-138.35H549.48V424.7c0-37.85,18.54-74.74,78-74.74h60.35V232.18s-54.76-9.35-107.13-9.35c-109.31,0-180.76,66.26-180.76,186.21V514.48H278.42V652.83H399.93V963.32q-39.22-.18-78.45-.49c-86.41-.75-148.69-42.46-186.62-119.88-13-26.63-18.3-55.51-18.34-85.08-.16-144.81-.5-289.62.06-434.43.4-103.89,69.7-185.39,172-203.46,13.71-2.42,27.93-2.79,41.91-2.81,139-.2,278,.11,417.06-.21,74.25-.17,134.87,26.82,178.74,87.77,24.84,34.51,36.35,73.77,36.64,116,.5,73.43.14,146.87.14,220.31C963.12,613.23,963.25,685.42,963.09,757.62Z"/></svg>
                        <svg viewBox="0 0 1080 1080" onClick={()=>onclickSocial('youtube')}><path className="cls-1" d="M0,295.86a116.92,116.92,0,0,1,2.74-25.18c3.64-16.52,6.84-33.21,12.53-49C57.4,104.61,138.37,31.11,261.49,7,284,2.63,307.3.93,330.24.85,471.31.3,612.41,2.4,753.44,0c134.14-2.24,258.09,83.37,304.24,203.27,13.92,36.17,22.71,71.69,22.3,111.07-1.55,149.32-.58,298.66-.63,448-.06,158.35-118.86,293.94-276.09,314.92a291.68,291.68,0,0,1-38.17,2.33c-149.32.16-298.65-.81-448,.37C182.42,1081.05,43.46,985.14,7.54,832.34c-1.79-7.6-3.4-15.25-5-22.89A117.68,117.68,0,0,1,0,785.2Q0,540.53,0,295.86ZM963.12,541c0-73.44.36-146.88-.14-220.31-.29-42.22-11.8-81.48-36.64-116C882.47,143.79,821.85,116.8,747.6,117c-139,.32-278,0-417.06.21-14,0-28.19.39-41.91,2.81-102.34,18.07-171.63,99.57-172,203.46-.56,144.81-.23,289.62-.06,434.43,0,29.58,5.29,58.45,18.34,85.08,37.93,77.41,100.21,119.13,186.62,119.88,144.37,1.25,288.76.67,433.15.25a224.94,224.94,0,0,0,52.57-6.31c93.21-22.92,155.67-103.16,155.89-199.15C963.25,685.42,963.12,613.23,963.12,541Z"/><path className="cls-1" d="M399.53,823.44a103.74,103.74,0,0,1-103.32-103.5V360.09a103.33,103.33,0,0,1,155-89.54l311.65,180a103.31,103.31,0,0,1,0,178.94L451.21,809.43A103.46,103.46,0,0,1,399.53,823.44Zm8-449.47V706L695.08,540Z"/></svg>
                    </div>
                    <div className="copyright">
                        copyright&copy;2023 Sembilan Autoseries Indonesia. <br/> All Rights Reserved
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer