import {useContext, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext.js';
import { makeRequest } from '../axios.js';
import { ToastContainer, toast } from 'react-toastify';
import {FOLDER_MEDIA_PATH} from '../utils.js';
import 'react-toastify/dist/ReactToastify.css';

const MobileMenuList = ({activeLink,updateActiveLink,bgOverlay,changeBgOverlay,menu,onUpdateMenu}) => {
    const {login,logout,currentUser,register} = useContext(AuthContext);
    //login
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [inputs,setInputs] = useState({email:'',password:''});
    const [emailLabelActive,setEmailLabelActive] = useState('');
    const [emailFilled,setEmailFilled] = useState('');
    const [passwordLabelActive,setPasswordLabelActive] = useState('');
    const [passwordFilled,setPasswordFilled] = useState('');

    //register
    const usernameRegisterRef = useRef(null);
    const emailRegisterRef = useRef(null);
    const passwordRegisterRef = useRef(null);
    const [inputRegister,setInputRegister] = useState({username:'',email:'',password:''});
    const [usernameRegisterLabelActive,setUsernameRegisterLabelActive] = useState('');
    const [usernameRegisterFilled,setUsernameRegisterFilled] = useState('');
    const [emailRegisterLabelActive,setEmailRegisterLabelActive] = useState('');
    const [emailRegisterFilled,setEmailRegisterFilled] = useState('');
    const [passwordRegisterLabelActive,setPasswordRegisterLabelActive] = useState('');
    const [passwordRegisterFilled,setPasswordRegisterFilled] = useState('');

    const [usermenus,setUsermenus] = useState([]);
    const [productSelected,setProductSelected] = useState(false);
    const [downloadSelected,setDownloadSelected] = useState(false);
    const [stateLogin,setStateLogin] = useState(true);
    const [categories,setCategories] = useState([]);
    const navigate = useNavigate();
    const [maps,setMaps] = useState({});

    const initUsermenus = async () => {
        try {
            const result = await makeRequest.get("menus/find-by-role-name?roleName=user");
            setUsermenus(result.data.data);
        } catch (error) {
            console.log(error);
            setUsermenus([]);
        }
    }
    const initCategories = async () => {
        try {
            const result = await makeRequest.get("categories/find-all");
            setCategories(result.data.data);
            let mapss = {};
            result.data.data.map(data=>{
                let subCategories = [];
                data.sub_categories.map(dataa=>subCategories.push(dataa.name));
                mapss[data.name] = subCategories;
            });
            setMaps(mapss);
        } catch (error) {
            console.log(error);
            setCategories([]);
        }
    }
    const callToastNotification = (message,type) => {
        if(type === 'success'){
            toast.success(message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if(type === 'error'){
            toast.error(message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const handleLogin = async e => {
        e.preventDefault();
        const result = await login(inputs);
        if(result.status === 'ERROR'){
            if(result.message.includes('verifikasi')){
                navigate('/verifikasi-otp?email='+inputs.email);
            }
            callToastNotification(result.message,'error');
        } else {
            setInputs({email:'',password:''});
            handleUpdateActiveLink(e,'')
            changeBgOverlay(e,false);
            callToastNotification('success log in!','success');
        }
    };
    const handleLogout = async e => {
        e.preventDefault();
        try {
            await logout();
            navigate("/");
            handleUpdateActiveLink(e,'')
            changeBgOverlay(e,false);
            callToastNotification('success log out!','success');
        } catch(err){
            callToastNotification('log out error!','error');
        }
    };
    const handleRegister = async e => {

        const result = await register(inputRegister);
        if(result.status==='SUCCESS'){
            //generate otp
            const resultOtp = await makeRequest.get('generate-otp');
            await makeRequest.put('users/update-otp?email='+inputRegister.email,{otp:resultOtp.data.data.otp});

            //send email verify account
            const emailDto = {
                from:process.env.REACT_APP_SMTP_EMAIL_FROM,
                to:inputRegister.email,
                subject:'Verifikasi akun '+inputRegister.email,
                otp:resultOtp.data.data.otp,
                type:'verification'
            };
            await makeRequest.post('send-email',emailDto);

            navigate('/verifikasi-otp?email='+inputRegister.email)
            setInputRegister({username:'',email:'',password:''});
            handleUpdateActiveLink(e,'')
            changeBgOverlay(e,false);
            callToastNotification('Daftar akun berhasil! Mohon untuk cek alamat email anda, untuk melanjutkan proses verifikasi.','success');
        } else {
            callToastNotification('Daftar akun gagal!','error');
        }
    };
    const handleUpdateActiveLink = (e,value) => {
        updateActiveLink(e,value);
    }
    useEffect(()=>{
        initUsermenus();
        initCategories();
    },[])
    const navigateTo = (e,path) => {
        e.preventDefault();
        navigate(path);
        updateActiveLink(e,'');
    }
    const getSubCategoriesByCategory = async(categoryName) => {
        try {
            const response = await makeRequest.get("sub-categories/find-by-category-name?categoryName="+categoryName);
            const result = response.data.data.map(category=>category.name);
            return result;
        } catch (error) {
            return [];
        }
    }
    const navigateDownloadOrProduct = async(e,path,categoryName) => {
        e.preventDefault();
        if(categoryName!==''){
            const subCategories = await getSubCategoriesByCategory(categoryName);
            navigate("/produk?kategori="+categoryName+"&halaman=1&urutkan=produk_terlaris&subKategori="+subCategories);
        } else {
            navigate(path);
        }
        updateActiveLink(e,'');
        setProductSelected(false);
        setDownloadSelected(false);
    }
    const navigateMenu = (e,name,path) => {
        e.preventDefault();
        if(name==='Produk' || name==='Unduh'){
            if(name==='Produk'){
                setProductSelected(true);
                setDownloadSelected(false);
            } else {
                setProductSelected(false);
                setDownloadSelected(true);
            }
        } else {
            setProductSelected(false);
            setDownloadSelected(false);
            navigate(path);
            updateActiveLink(e,'');
        }
    }
    const updateProductSelected = e => {
        e.preventDefault();
        setProductSelected(false);
    }
    const updateDownloadSelected = e => {
        e.preventDefault();
        setDownloadSelected(false);
    }
    const changeStateLogin = (e,value) => {
        e.preventDefault();
        setStateLogin(value);
    }
    const handleChange = (e,type) => {
        e.preventDefault();
        if(type==='email'){
            setInputs((prev) => ({...prev, email:e.target.value}));
        } else {
            setInputs((prev) => ({...prev, password:e.target.value}));
        }
    }
    const handleFocus = (e,type) => {
        e.preventDefault();
        if(type==='email'){
            emailRef.current.focus();
            setEmailLabelActive('active');
        } else {
            passwordRef.current.focus();
            setPasswordLabelActive('active');
        }
    }
    const handleBlur = (e,type) => {
        const {value} = e.target;
        if(type==='email'){
            emailRef.current.blur();
            if(value!==''){
                setEmailFilled('filled')
            } else {
                setEmailLabelActive('');
                setEmailFilled('');
            }
        } else {
            passwordRef.current.blur();
            if(value!==''){
                setPasswordFilled('filled')
            } else {
                setPasswordLabelActive('');
                setPasswordFilled('');
            }
        }
    }
    const handleRegisterChange = (e,type) => {
        e.preventDefault();
        if(type==='username'){
            setInputRegister((prev) => ({...prev, username:e.target.value}));
        } else if(type==='email'){
            setInputRegister((prev) => ({...prev, email:e.target.value}));
        } else if(type==='password'){
            setInputRegister((prev) => ({...prev, password:e.target.value}));
        }
    }
    const handleRegisterFocus = (e,type) => {
        e.preventDefault();
        if(type==='username'){
            usernameRegisterRef.current.focus();
            setUsernameRegisterLabelActive('active');
        } if(type==='email'){
            emailRegisterRef.current.focus();
            setEmailRegisterLabelActive('active');
        } else if(type==='password'){
            passwordRegisterRef.current.focus();
            setPasswordRegisterLabelActive('active');
        }
    }
    const handleRegisterBlur = (e,type) => {
        const {value} = e.target;
        if(type==='username'){
            usernameRegisterRef.current.blur();
            if(value!==''){
                setUsernameRegisterFilled('filled')
            } else {
                setUsernameRegisterLabelActive('');
                setUsernameRegisterFilled('');
            }
        } else if(type==='email'){
            emailRegisterRef.current.blur();
            if(value!==''){
                setEmailRegisterFilled('filled')
            } else {
                setEmailRegisterLabelActive('');
                setEmailRegisterFilled('');
            }
        } else if(type==='password'){
            passwordRegisterRef.current.blur();
            if(value!==''){
                setPasswordRegisterFilled('filled')
            } else {
                setPasswordRegisterLabelActive('');
                setPasswordRegisterFilled('');
            }
        }
    }
  return (
    <div className={activeLink === 'mobile-menu' || activeLink === 'mobile-usermenu' ? "mobile-menu-list active" : "mobile-menu-list"}>
        <ToastContainer/>
        <ul className="tab-content-menu">
            <li className={activeLink === 'mobile-menu' ? "toptab active" : "toptab"}>
                <a className="toptab-left" data-tab="tab-menulist" onClick={(e)=>handleUpdateActiveLink(e,'mobile-menu')}>
                    <svg viewBox="0 0 23 23" fill="none" className="toptab-icon">
                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.9" className="rect1" stroke="#FFFFFF"></rect>
                        <rect y="13" width="10" height="10" rx="2.4" className="rect"></rect>
                        <rect x="13" width="10" height="10" rx="2.4" className="rect"></rect>
                        <rect x="13" y="13" width="10" height="10" rx="2.4" className="rect"></rect>
                    </svg>
                    <span>Menu</span>
                </a>
            </li>
            <li className={activeLink === 'mobile-usermenu' ? "toptab active" : "toptab"}>
                <a className="toptab-right" data-tab="tab-menulogin" onClick={(e)=>handleUpdateActiveLink(e,'mobile-usermenu')}>
                    <svg viewBox="0 0 400 400" className="toptab-icon"><g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10"><g transform="scale(0.1)" id="g12"><path id="path14" d="m 1506.87,2587.11 c -225.04,0 -408.14,-183.08 -408.14,-408.11 0,-225.06 183.1,-408.13 408.14,-408.13 225.02,0 408.13,183.07 408.13,408.13 0,225.03 -183.11,408.11 -408.13,408.11 z m 0,-1038.56 c -347.64,0 -630.432,282.79 -630.432,630.45 0,347.63 282.792,630.43 630.432,630.43 347.63,0 630.42,-282.8 630.42,-630.43 0,-347.66 -282.79,-630.45 -630.42,-630.45 v 0"></path><path id="path16" d="M 399.648,361.789 H 2614.07 c -25.06,261.531 -139.49,503.461 -327.47,689.831 -124.25,123.14 -300.78,193.96 -483.86,193.96 h -591.76 c -183.61,0 -359.601,-70.82 -483.863,-193.96 C 539.148,865.25 424.719,623.32 399.648,361.789 Z M 2730.69,139.461 H 283.035 c -61.558,0 -111.16,49.59 -111.16,111.16 0,363.438 141.68,704 398.32,959.019 165.657,164.55 399.414,258.82 640.785,258.82 h 591.76 c 241.94,0 475.14,-94.27 640.8,-258.82 256.63,-255.019 398.31,-595.581 398.31,-959.019 0,-61.57 -49.59,-111.16 -111.16,-111.16 v 0"></path></g></g></svg>
                    <span>Masuk</span>
                </a>
            </li>
        </ul>
        <div className="tab-content">
            <div className="tab-content-wrapper">
                <div className="tab-panel" id="tab-menulist" style={{display: activeLink === 'mobile-menu' ? 'block' : 'none'}}>
                    <div className="search-menu-wrapper">
                        <input type="text" id="searchMenu" name="search menu" placeholder="Search here .. " autoComplete="on" />
                        <a href="#" className="mobile-search-btn">
                            <svg viewBox="0 0 400 400"><g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10"><g transform="scale(0.1)" id="g12"><path id="path14" d="m 1312.7,795.5 c -472.7,0 -857.204,384.3 -857.204,856.7 0,472.7 384.504,857.2 857.204,857.2 472.7,0 857.3,-384.5 857.3,-857.2 0,-472.4 -384.6,-856.7 -857.3,-856.7 z M 2783.9,352.699 2172.7,963.898 c 155.8,194.702 241.5,438.602 241.5,688.302 0,607.3 -494.1,1101.4 -1101.5,1101.4 -607.302,0 -1101.399,-494.1 -1101.399,-1101.4 0,-607.4 494.097,-1101.501 1101.399,-1101.501 249.8,0 493.5,85.5 687.7,241 L 2611.7,181 c 23,-23 53.6,-35.699 86.1,-35.699 32.4,0 63,12.699 86,35.699 23.1,22.801 35.8,53.301 35.8,85.898 0,32.602 -12.7,63 -35.7,85.801"></path></g></g></svg>
                        </a>
                    </div>
                    {usermenus.length>0 && usermenus.map(data=>{
                        return (
                            <div key={data.id}>
                                {data.name==='Beranda' &&
                                    <div className="menu-horizon-list" onClick={(e)=>navigateMenu(e,data.name,data.path)}>
                                        <a role="button">
                                            {data.name}
                                        </a>
                                    </div>
                                }
                                {data.name==='Produk' &&
                                    <div className="menu-horizon-list" onClick={(e)=>navigateMenu(e,data.name,data.path)}>
                                        <a role="button" className="has-sub js-has-sub" data-sub="sub-product">
                                            {data.name}
                                        </a>
                                    </div>
                                }
                                {data.name==='Berita' &&
                                    <div className="menu-horizon-list" onClick={(e)=>navigateMenu(e,data.name,data.path)}>
                                        <a role="button">
                                            {data.name}
                                        </a>
                                    </div>
                                }
                                {data.name==='Garansi' &&
                                    <div className="menu-horizon-list" onClick={(e)=>navigateMenu(e,data.name,data.path)}>
                                        <a role="button">
                                            {data.name}
                                        </a>
                                    </div>
                                }
                                {data.name==='Unduh' &&
                                    <div className="menu-horizon-list" onClick={(e)=>navigateMenu(e,data.name,data.path)}>
                                        <a role="button" className="has-sub js-has-sub" data-sub="sub-download">
                                            {data.name}
                                        </a>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className="tab-panel" id="tab-menulogin" style={{display: activeLink === 'mobile-usermenu' ? 'block' : 'none'}}>
                    <div className="menulogin-wrapper">
                        {!currentUser && stateLogin ?
                            <div className="form-login">
                                <form>
                                    <div className="form-group mb10px">
                                        <div className="mb0">
                                            <input type="text" ref={emailRef} onClick={(e) => handleFocus(e,'email')} onBlur={(e) => handleBlur(e,'email')} onChange={(e) => handleChange(e,'email')} id="emailLogin" name="emailLogin" className="form-control login-input" autoComplete="on" required/>
                                            <label className={`${emailLabelActive} ${emailFilled}`} htmlFor="emailLogin">Email</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div>
                                            <input type="password" ref={passwordRef} onClick={(e) => handleFocus(e,'password')} onBlur={(e) => handleBlur(e,'password')} onChange={(e) => handleChange(e,'password')} id="passwordLogin" name="passwordLogin" className="form-control login-input" autoComplete="off" required/>
                                            <label className={`${passwordLabelActive} ${passwordFilled}`} htmlFor="passwordLogin">Password</label>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <a href="#">Forgot your password?</a>
                                    </div>
                                    <button type="button" className="btnNine btnNine-create js-btnlogin-mobile" onClick={handleLogin}>
                                        <svg viewBox="0 0 1024 1024">
                                        <circle cx="512" cy="512" r="512" style={{fill:'#fff'}}/>
                                        <path d="m458.15 617.7 18.8-107.3a56.94 56.94 0 0 1 35.2-101.9V289.4h-145.2a56.33 56.33 0 0 0-56.3 56.3v275.8a33.94 33.94 0 0 0 3.4 15c12.2 24.6 60.2 103.7 197.9 164.5V622.1a313.29 313.29 0 0 1-53.8-4.4zM656.85 289h-144.9v119.1a56.86 56.86 0 0 1 35.7 101.4l18.8 107.8A320.58 320.58 0 0 1 512 622v178.6c137.5-60.5 185.7-139.9 197.9-164.5a33.94 33.94 0 0 0 3.4-15V345.5a56 56 0 0 0-16.4-40 56.76 56.76 0 0 0-40.05-16.5z" style={{fill:'#363c4f'}}/>
                                        </svg>
                                        Masuk
                                    </button>
                                    <div className="or-create">
                                        <span>atau</span>
                                        <div>
                                            <a role="button" className="register-btn" onClick={e=>changeStateLogin(e,false)}>Daftar Sekarang</a>
                                        </div>
                                    </div>
                                </form>
                            </div> : !currentUser && !stateLogin ?
                            <div className="form-register">
                                <form>
                                    <div className="form-group mb10px">
                                        <div className="mb0">
                                            <input type="text" ref={usernameRegisterRef} onClick={(e) => handleRegisterFocus(e,'username')} onBlur={(e) => handleRegisterBlur(e,'username')} onChange={(e) => handleRegisterChange(e,'username')} id="username" name="username" className="form-control login-input" autoComplete="on" required/>
                                            <label className={`${usernameRegisterLabelActive} ${usernameRegisterFilled}`} htmlFor="username">Username</label>
                                        </div>
                                    </div>
                                    <div className="form-group mb10px">
                                        <div className="mb0">
                                            <input type="text" ref={emailRegisterRef} onClick={(e) => handleRegisterFocus(e,'email')} onBlur={(e) => handleRegisterBlur(e,'email')} onChange={(e) => handleRegisterChange(e,'email')} id="email" name="email" className="form-control login-input" autoComplete="on" required/>
                                            <label className={`${emailRegisterLabelActive} ${emailRegisterFilled}`} htmlFor="email">Email Address</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div>
                                            <input type="password" ref={passwordRegisterRef} onClick={(e) => handleRegisterFocus(e,'password')} onBlur={(e) => handleRegisterBlur(e,'password')} onChange={(e) => handleRegisterChange(e,'password')} id="password" name="password" className="form-control login-input" autoComplete="off" required/>
                                            <label className={`${passwordRegisterLabelActive} ${passwordRegisterFilled}`} htmlFor="password">Password</label>
                                        </div>
                                    </div>
                                    <button type="button" className="btnNine btnNine-create" onClick={handleRegister}>
                                        Daftar
                                    </button>
                                    <div className="or-create">
                                        <span>atau</span>
                                        <div>
                                            <a className="login-btn" onClick={e=>changeStateLogin(e,true)}>Kembali ke login</a>
                                        </div>
                                    </div>
                                </form>
                            </div> : ''
                        }
                        {currentUser &&
                            <div className="form-after-login">
                                <div className="userphoto-mobile-wrapper">
                                    <div className="userdesc-mobile">
                                        <div className="name">{currentUser.name}</div>
                                        <div className="status">
                                            <div>Member Nine Autoseries</div>
                                            <svg viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.8613 9.36335L10.7302 9.59849C10.5862 9.85677 10.5142 9.98591 10.402 10.0711C10.2897 10.1563 10.1499 10.188 9.87035 10.2512L9.61581 10.3088C8.63195 10.5314 8.14001 10.6427 8.02297 11.0191C7.90593 11.3955 8.2413 11.7876 8.91204 12.572L9.08557 12.7749C9.27617 12.9978 9.37147 13.1092 9.41435 13.2471C9.45722 13.385 9.44281 13.5336 9.41399 13.831L9.38776 14.1018C9.28635 15.1482 9.23565 15.6715 9.54206 15.9041C9.84847 16.1367 10.3091 15.9246 11.2303 15.5005L11.4686 15.3907C11.7304 15.2702 11.8613 15.2099 12 15.2099C12.1387 15.2099 12.2696 15.2702 12.5314 15.3907L12.7697 15.5005C13.6909 15.9246 14.1515 16.1367 14.4579 15.9041C14.7644 15.6715 14.7136 15.1482 14.6122 14.1018L14.586 13.831C14.5572 13.5336 14.5428 13.385 14.5857 13.2471C14.6285 13.1092 14.7238 12.9978 14.9144 12.7749L15.088 12.572C15.7587 11.7876 16.0941 11.3955 15.977 11.0191C15.86 10.6427 15.3681 10.5314 14.3842 10.3088L14.1296 10.2512C13.8501 10.188 13.7103 10.1563 13.598 10.0711C13.4858 9.98592 13.4138 9.85678 13.2698 9.5985L13.1387 9.36335C12.6321 8.45445 12.3787 8 12 8C11.6213 8 11.3679 8.45446 10.8613 9.36335Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="photo">
                                        <img src={currentUser.imageName ? FOLDER_MEDIA_PATH+currentUser.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="profileImage" />
                                    </div>
                                </div>
                                <div className="menu-horizon-list">
                                    <a role="button" onClick={(e)=>navigateTo(e,"/profil")}>Halaman Saya</a>
                                </div>
                                <div className="menu-horizon-list">
                                    <a role="button" onClick={(e)=>navigateTo(e,"/ubah-profil")}>Ganti Profil</a>
                                </div>
                                <div className="menu-horizon-list">
                                    <a role="button" onClick={(e)=>navigateTo(e,"/daftar-alamat")}>Daftar Alamat</a>
                                </div>
                                <div className="menu-horizon-list">
                                    <a className="logout-mobile-menu js-btnlogout-mobile" onClick={handleLogout}>Keluar</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={productSelected ? "sub-tab-content-wrapper active":"sub-tab-content-wrapper"} id="sub-product">
                    <div className="sub-content-header js-backto-sub" onClick={updateProductSelected}><i className="fa fa-angle-left"></i> Produk</div>
                    <div className="sub-title-menu">Lampu</div>
                    <ul className="menu-lv3 two-column">
                        {categories.length > 0 && categories.map(category=>{
                            return (
                                <li key={category.id}><a onClick={(e)=>navigateDownloadOrProduct(e,"/produk?kategori="+category.name+"&halaman=1&urutkan=produk_terlaris",category.name)} className="link-menu">{category.name}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div className={downloadSelected ? "sub-tab-content-wrapper active":"sub-tab-content-wrapper"} id="sub-download">
                    <div className="sub-content-header js-backto-sub" onClick={updateDownloadSelected}><i className="fa fa-angle-left"></i> Unduh</div>
                    <ul className="menu-lv3">
                        <li><a role='button' className="link-menu" onClick={(e)=>navigateDownloadOrProduct(e,"/brosur","")}>Brosur</a></li>
                        <li><a role='button' className="link-menu" onClick={(e)=>navigateDownloadOrProduct(e,"/katalog","")}>Katalog</a></li>
                    </ul>
                </div>
            </div>
            <div className="close-menu-mobile js-close-menu-mobile" onClick={(e)=>handleUpdateActiveLink(e,'')}>Tutup</div>
        </div>
    </div>
  )
}

export default MobileMenuList