import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/authContext.js";
import { FOLDER_MEDIA_PATH } from "../utils.js";
import { makeRequest } from "../axios.js";
import { useState } from "react";
import {useNavigate} from 'react-router-dom';

const ChangeProfile = ({loginClicked,setLoginClicked}) => {
    const {currentUser,login} = useContext(AuthContext);
    const navigate = useNavigate();
    const fileRef = useRef(null);
    const [isOpen,setIsOpen] = useState(false);
    const [type,setType] = useState('close');
    const [input,setInput] = useState({
        id:'',
        name:'',
        email:'',
        phone:'',
        provinsi:'',
        kabupaten:'',
        kecamatan:'',
        kelurahan:'',
        address:'',
        gender:'',
        password:'',
        confirmPassword:'',
        imageName:''
    });
    const [provinsi,setProvinsi] = useState([]);
    const [kabupaten,setKabupaten] = useState([]);
    const [kecamatan,setKecamatan] = useState([]);
    const [kelurahan,setKelurahan] = useState([]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('users/find-by-email?email='+currentUser.email);
            const resultData = result.data.data;
            let valueInput = {
                id:resultData.id ? resultData.id : '',
                name:resultData.name ? resultData.name : '',
                email:resultData.email ? resultData.email : '',
                phone:resultData.phoneNumber ? resultData.phoneNumber : '',
                provinsi:resultData.user_addresses.length > 0 && resultData.user_addresses[0].provinsiId ? resultData.user_addresses[0].provinsiId : '',
                kabupaten:resultData.user_addresses.length > 0 && resultData.user_addresses[0].kabupatenId ?  resultData.user_addresses[0].kabupatenId : '',
                kecamatan:resultData.user_addresses.length > 0 && resultData.user_addresses[0].kecamatanId ?  resultData.user_addresses[0].kecamatanId : '',
                kelurahan:resultData.user_addresses.length > 0 && resultData.user_addresses[0].kelurahanId ?  resultData.user_addresses[0].kelurahanId : '',
                address:resultData.user_addresses ? resultData.user_addresses[0]?.addressDetails : '',
                gender:resultData.gender ? resultData.gender : '',
                password:resultData.password ? resultData.password : '',
                confirmPassword:resultData.password ? resultData.password : '',
                imageName:resultData.imageName ? resultData.imageName : ''
            }
            setInput(valueInput);
        } catch (error) {
            setInput({
                id:'',
                name:'',
                email:'',
                phone:'',
                provinsi:'',
                kabupaten:'',
                kecamatan:'',
                kelurahan:'',
                address:'',
                gender:'',
                password:'',
                confirmPassword:'',
                imageName:''
            });
        }
    }
    const openOrCloseForm = async(value,formType) => {
        if(formType==='close'){
            let isValid = (input.name != null && input.name !== '')
                && (input.email != null && input.email !== '')
                && (input.phone != null && input.phone !== '')
                && (input.provinsi != null && input.provinsi !== '')
                && (input.kabupaten != null && input.kabupaten !== '')
                && (input.kecamatan != null && input.kecamatan !== '')
                && (input.kelurahan != null && input.kelurahan !== '')
                && (input.address != null && input.address !== '')
                && (input.gender != null && input.gender !== '');
            if(!isValid){
                alert('Terjadi kesalahan saat ubah profil, dimohon untuk memperbaikinya. Terima kasih!');
                return;
            }

            isValid = input.password === input.confirmPassword;
            if(isValid){
                try {
                    await makeRequest.put('users?id='+input.id,input);
                    login({email:input.email,password:input.password});
                    initData();
                    alert("Ubah Profil berhasil!");
                    setIsOpen(value);
                    setType(formType);
                } catch (error) {
                    alert('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!');
                }
            } else {
                alert("Password dan Konfirmasi Password tidak sama, mohon diperbaiki.");
                return;
            }
        } else {
            setIsOpen(value);
            setType(formType);
        }
    }
    const inputChange = (e,type,genderType) => {
        if(genderType!==null){
            setInput((prev)=>({...prev,gender:genderType}));
        } else {
            setInput((prev)=>({...prev,[type]:e.target.value}));
        }
    }
    const uploadPhoto = async(e) => {
        const formData = new FormData();
        formData.append('files',e.target.files[0]);
        try {
            const result = await makeRequest.post('upload',formData);
            if(result.status===200){
                await makeRequest.put('users/update-image?id='+input.id,{imageName:result.data.data[0].filename})
                setInput(prev => ({ ...prev, imageName: result.data.data[0].filename }));
                console.log('unggah gambar ke server berhasil');
                login({email:input.email,password:input.password});
            }
        } catch (error) {
            alert('unggah gambar ke server gagal: '+JSON.stringify(error))
        }
    }
    const uploadClick = () => {
        fileRef.current.click();
    }
    const initProvinsi = async() => {
        try {
            const result = await makeRequest.get('address/provinsi');
            setProvinsi(result.data.data);
        } catch (error) {
            setProvinsi([]);
        }
    }
    const initKabupaten = async() => {
        try {
            const result = await makeRequest.get('address/kabupaten/find-by-provinsi-id?provinsiId='+input.provinsi);
            setKabupaten(result.data.data);
        } catch (error) {
            setKabupaten([]);
        }
    }
    const initKecamatan = async() => {
        try {
            const result = await makeRequest.get('address/kecamatan/find-by-kabupaten-id?kabupatenId='+input.kabupaten);
            setKecamatan(result.data.data);
        } catch (error) {
            setKecamatan([]);
        }
    }
    const initKelurahan = async() => {
        try {
            const result = await makeRequest.get('address/kelurahan/find-by-kecamatan-id?kecamatanId='+input.kecamatan);
            setKelurahan(result.data.data);
        } catch (error) {
            setKelurahan([]);
        }
    }
    const provinsiChanged = async(e)=>{
        setInput((prev) => ({...prev, provinsi:e.target.value,kabupaten:'',kecamatan:'',kelurahan:''}));
    }
    const kabupatenChanged = (e)=>{
        setInput((prev) => ({...prev, kabupaten:e.target.value,kecamatan:'',kelurahan:''}));
    }
    const kecamatanChanged = (e)=>{
        setInput((prev) => ({...prev, kecamatan:e.target.value,kelurahan:''}));
    }
    const kelurahanChanged = (e)=>{
        setInput((prev) => ({...prev, kelurahan:e.target.value}));
    }
    const check = (type) => {
        if(type==='kabupaten'){
            if(input.provinsi==null || input.provinsi===''){
                alert('Mohon untuk pilih provinsi terlebih dahulu. Terima kasih!');
                return;
            }
        } else if(type==='kecamatan') {
            if(input.kabupaten==null || input.kabupaten===''){
                alert('Mohon untuk pilih kabupaten terlebih dahulu. Terima kasih!');
                return;
            }
        } else if(type==='kelurahan'){
            if(input.kecamatan==null || input.kecamatan===''){
                alert('Mohon untuk pilih kecamatan terlebih dahulu. Terima kasih!');
                return;
            }
        }
    }
    useEffect(()=>{
        if(!currentUser){
            navigate("/");
        }
        initData();
        initProvinsi();
    },[]);
    useEffect(()=>{
        initKabupaten();
    },[input.provinsi]);
    useEffect(()=>{
        initKecamatan();
    },[input.kabupaten]);
    useEffect(()=>{
        initKelurahan();
    },[input.kecamatan]);
  return (
    <div className="section-profile profile-wrapper">
        {currentUser &&
            <div className="container">
                <div className="profile-title-page">Ubah Profil</div>
                <div className="fz20 semibold black">Profil Saya</div>
                <div className="gray2 fz14">Kelola informasi profil Anda untuk mengontrol, melindungi dan mengamankan akun.</div>
                <div className="content-profile">
                    <div className="inline-space chage-profile-wrapper">
                        <div className="left-change-profile">
                            <div className="left-user-photo">
                                <img src={input.imageName ? FOLDER_MEDIA_PATH+input.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="profileImage" />
                            </div>
                            <div className="upload-change-profile wrapper-upload-foto">
                                <button type="button" onClick={uploadClick} className="btnNine btnNine-default js-upload-photo">Pilih Foto</button>
                                <input ref={fileRef} type="file" id="file" name="upload change profile" className="fotoupload" onChange={uploadPhoto} accept="image/jpeg,image/gif,image/png"/>
                                <p>Besar file: Maksimum 10.000.000 bytes (10 Megabytes). Ekstensi file yang diperbolehkan: JPG .JPEG .PNG</p>
                            </div>
                            <div className="status-change-profile">
                                <svg viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.8613 9.36335L10.7302 9.59849C10.5862 9.85677 10.5142 9.98591 10.402 10.0711C10.2897 10.1563 10.1499 10.188 9.87035 10.2512L9.61581 10.3088C8.63195 10.5314 8.14001 10.6427 8.02297 11.0191C7.90593 11.3955 8.2413 11.7876 8.91204 12.572L9.08557 12.7749C9.27617 12.9978 9.37147 13.1092 9.41435 13.2471C9.45722 13.385 9.44281 13.5336 9.41399 13.831L9.38776 14.1018C9.28635 15.1482 9.23565 15.6715 9.54206 15.9041C9.84847 16.1367 10.3091 15.9246 11.2303 15.5005L11.4686 15.3907C11.7304 15.2702 11.8613 15.2099 12 15.2099C12.1387 15.2099 12.2696 15.2702 12.5314 15.3907L12.7697 15.5005C13.6909 15.9246 14.1515 16.1367 14.4579 15.9041C14.7644 15.6715 14.7136 15.1482 14.6122 14.1018L14.586 13.831C14.5572 13.5336 14.5428 13.385 14.5857 13.2471C14.6285 13.1092 14.7238 12.9978 14.9144 12.7749L15.088 12.572C15.7587 11.7876 16.0941 11.3955 15.977 11.0191C15.86 10.6427 15.3681 10.5314 14.3842 10.3088L14.1296 10.2512C13.8501 10.188 13.7103 10.1563 13.598 10.0711C13.4858 9.98592 13.4138 9.85678 13.2698 9.5985L13.1387 9.36335C12.6321 8.45445 12.3787 8 12 8C11.6213 8 11.3679 8.45446 10.8613 9.36335Z" />
                                </svg>
                                <p>Apresiasi kami untuk Anda sebagai <br/>Member <strong>Nine Autoseries</strong></p>
                            </div>
                        </div>
                        <div className="right-change-profile">
                            <div className={isOpen ? "bordered-box wrapper-form-editable active" : "bordered-box wrapper-form-editable"}>
                                <svg viewBox="0 0 24 24" fill="none" className="svg-star">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.8613 9.36335L10.7302 9.59849C10.5862 9.85677 10.5142 9.98591 10.402 10.0711C10.2897 10.1563 10.1499 10.188 9.87035 10.2512L9.61581 10.3088C8.63195 10.5314 8.14001 10.6427 8.02297 11.0191C7.90593 11.3955 8.2413 11.7876 8.91204 12.572L9.08557 12.7749C9.27617 12.9978 9.37147 13.1092 9.41435 13.2471C9.45722 13.385 9.44281 13.5336 9.41399 13.831L9.38776 14.1018C9.28635 15.1482 9.23565 15.6715 9.54206 15.9041C9.84847 16.1367 10.3091 15.9246 11.2303 15.5005L11.4686 15.3907C11.7304 15.2702 11.8613 15.2099 12 15.2099C12.1387 15.2099 12.2696 15.2702 12.5314 15.3907L12.7697 15.5005C13.6909 15.9246 14.1515 16.1367 14.4579 15.9041C14.7644 15.6715 14.7136 15.1482 14.6122 14.1018L14.586 13.831C14.5572 13.5336 14.5428 13.385 14.5857 13.2471C14.6285 13.1092 14.7238 12.9978 14.9144 12.7749L15.088 12.572C15.7587 11.7876 16.0941 11.3955 15.977 11.0191C15.86 10.6427 15.3681 10.5314 14.3842 10.3088L14.1296 10.2512C13.8501 10.188 13.7103 10.1563 13.598 10.0711C13.4858 9.98592 13.4138 9.85678 13.2698 9.5985L13.1387 9.36335C12.6321 8.45445 12.3787 8 12 8C11.6213 8 11.3679 8.45446 10.8613 9.36335Z" />
                                </svg>
                                <div className="wrapper-input-editable">
                                    <label>Nama</label>
                                    <input type="text" name="username" onChange={(e)=>inputChange(e,'name',null)} value={input.name} autoComplete="off" className="editable-form"/>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Email</label>
                                    <input type="text" name="email" onChange={(e)=>inputChange(e,'email',null)}  value={input.email} autoComplete="off" className="editable-form"/>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Telepon</label>
                                    <input type="text" name="email" onChange={(e)=>inputChange(e,'phone',null)}  value={input.phone} autoComplete="off" className="editable-form"/>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Provinsi</label>
                                    <select id='provinsi' onChange={(e)=>provinsiChanged(e)} value={input.provinsi} className='dropdown-custom' disabled={type==='close'}>
                                        <option value=''>--Pilih Provinsi--</option>
                                        {provinsi.length>0 && provinsi.map(prov=>(
                                            <option key={prov.id} value={prov.id}>{prov.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Kabupaten</label>
                                    <select id='kabupaten' onChange={(e)=>kabupatenChanged(e)} onClick={()=>check('kabupaten')} value={input.kabupaten} className='dropdown-custom' disabled={type==='close'}>
                                        <option value=''>--Pilih Kabupaten--</option>
                                        {kabupaten.length>0 && kabupaten.map(kab=>(
                                            <option key={kab.id} value={kab.id}>{kab.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Kecamatan</label>
                                    <select id='kecamatan' onChange={(e)=>kecamatanChanged(e)} onClick={()=>check('kecamatan')} value={input.kecamatan} className='dropdown-custom' disabled={type==='close'}>
                                        <option value=''>--Pilih Kecamatan--</option>
                                        {kecamatan.length>0 && kecamatan.map(kec=>(
                                            <option key={kec.id} value={kec.id}>{kec.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Kelurahan</label>
                                    <select id='kelurahan' onChange={(e)=>kelurahanChanged(e)} onClick={()=>check('kelurahan')} value={input.kelurahan} className='dropdown-custom' disabled={type==='close'}>
                                        <option value=''>--Pilih Kelurahan--</option>
                                        {kelurahan.length>0 && kelurahan.map(kel=>(
                                            <option key={kel.id} value={kel.id}>{kel.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Alamat</label>
                                    <textarea className="editable-form" onChange={(e)=>inputChange(e,'address',null)}  value={input.address}></textarea>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Jenis Kelamin</label>
                                    <div className="inline-space w40">
                                        <div className="custom-checkbox-cart">
                                            <input type="radio" name="radiogender" id="checkboxmale" onChange={(e)=>inputChange(e,'address','MALE')} checked={input.gender==='MALE'} className="editable-form"/>
                                            <span>Laki - laki</span>
                                        </div>
                                        <div className="custom-checkbox-cart">
                                            <input type="radio" name="radiogender" id="checkboxfemale" onChange={(e)=>inputChange(e,'address','FEMALE')} checked={input.gender==='FEMALE'} className="editable-form"/>
                                            <span>Perempuan</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper-input-editable">
                                    <label>Password</label>
                                    <input type={type==="open" ? "text" : "password"} name="password" onChange={(e)=>inputChange(e,'password',null)} value={input.password} autoComplete="off" className="editable-form editable-psw"/>
                                </div>
                                <div className="wrapper-input-editable mb1">
                                    <label>Konfirmasi Password</label>
                                    <input type={type==="open" ? "text" : "password"} name="konpassword" onChange={(e)=>inputChange(e,'confirmPassword',null)} value={input.confirmPassword} autoComplete="off" className="editable-form editable-psw"/>
                                </div>
                                <div className="flex">
                                    <button type="button" onClick={()=>openOrCloseForm(true,'open')} className="btnNine btnNine-default js-edit-profile" disabled={type==='open'}>Ubah</button>
                                    <button type="button" onClick={()=>openOrCloseForm(false,'close')} className="btnNine btnNine-default js-save-profile" disabled={type==='close'}>Simpan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default ChangeProfile