import Slider from "react-slick";
import {FOLDER_MEDIA_PATH} from '../utils.js';
import {useNavigate} from 'react-router-dom';
import { useState } from "react";

const SlideShow = ({datas}) => {
    const navigate = useNavigate();
    const [currentIndex,setCurrentIndex] = useState(0);
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        arrows: false,
        dots: true,
        autoplay:true,
        autoplaySpeed: 6500,
        fade: true,
        infinite: true,
        pauseOnHover:false,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentIndex(newIndex);
        }
    }
    const navigateTo = () => {
        navigate("/detail-produk/"+datas[currentIndex].product.path);
    }

  return (
    <div className="section hero-slider">
        {datas.length>0 &&
            <Slider className="slideshow" {...settings}>
                {datas.map((data,idx)=>(
                    <figure key={idx} className="slide">
                        <div className="slide__img-wrap">
                            <div className="slide__img">
                                {data.mainImageType==='video' &&
                                    <video autoPlay muted loop data-autoplay id="myVideo">
                                        <source src={FOLDER_MEDIA_PATH+data.mainImagePath} type="video/mp4"/>
                                        Your browser does not support HTML5 video.
                                    </video>
                                }
                                {data.mainImageType==='image' &&
                                    <img src={FOLDER_MEDIA_PATH+data.mainImagePath} alt="slideshowImage" />
                                }
                            </div>
                        </div>
                        <figcaption className="slide__caption">
                            <img src={FOLDER_MEDIA_PATH+data.subImagePath} className="logo-product" alt="productImage" />
                            <h2 className="slides__caption-headline">
                                <span className="text-row"><span className="product-name">{data.title}</span></span>
                                <span className="text-row"><span className="product-desc">{data.description}</span></span>
                            </h2>
                            <a role="button" className="slides__caption-link btnNine btnNine-default" onClick={()=>navigateTo()}>Beli Langsung</a>
                        </figcaption>
                    </figure>
                ))}
            </Slider>
        }
    </div>
  )
}

export default SlideShow