import Category from '../components/Category';
import SlideShow from '../components/SlideShow';
import Guarantee from '../components/Guarantee';
import Promo from '../components/Promo';
import News from '../components/News';
import '../sass/main.scss';
import { useEffect,useState } from 'react';
import { makeRequest } from "../axios.js";

const Home = () => {
  const [carousels,setCarousels] = useState([]);
  const [categories,setCategories] = useState([]);
  const [promos,setPromos] = useState([]);
  const [largePromo,setLargePromo] = useState([]);
  const [news,setNews] = useState([]);
  useEffect(()=>{
    getCarousels();
    getCategories();
    getPromo();
    getLatestNews();
  },[]);
  const getCarousels = async () => {
    try {
      const result = await makeRequest.get("carousels/find-all");
      setCarousels(result.data.data);
    } catch (error) {
      console.log(error);
      setCarousels([]);
    }
  };
  const getCategories = async () => {
    try {
      const result = await makeRequest.get("categories/find-all");
      setCategories(result.data.data);
    } catch (error) {
      setCategories([]);
      console.log(error);
    }
  };
  const getPromo = async () => {
    try {
      const result = await makeRequest.get("promos/find-major");
      setLargePromo(result.data.data);
    } catch (error) {
      console.log(error);
      setLargePromo([]);
    }

    try {
      const result = await makeRequest.get("promos/find-minor");
      setPromos(result.data.data);
    } catch (error) {
      console.log(error);
      setPromos([]);
    }
  };
  const getLatestNews = async () => {
    try {
      const result = await makeRequest.get("news/find-latest");
      setNews(result.data.data);
    } catch (error) {
      setNews([]);
      console.log(error);
    }
  };
  return (
    <>
        <SlideShow datas={carousels}/>
        <Category datas={categories}/>
        <Promo largePromo={largePromo} promos={promos} />
        <Guarantee/>
        <News datas={news}/>
    </>
  )
}

export default Home