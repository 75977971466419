import { makeRequest } from "../axios.js";
import { AuthContext } from "../context/authContext.js";
import { HeaderContext } from "../context/headerContext.js";
import {useContext,useCallback,useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import garansiImg from '../img/garansi.svg';
import discountImg from '../img/discount.svg';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import queryString from 'query-string';
import {FOLDER_MEDIA_PATH,thousandSeparator} from '../utils.js';
import { useLoading } from '../context/LoadingContext.js';
// import { useMutation, useQueryClient,useQuery } from "@tanstack/react-query";

const UserWishlist = ({onChange,setLoginClicked}) => {

    const { showLoader, hideLoader } = useLoading();
    // gsap.registerPlugin(ScrollTrigger);
    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext);
    const {checkCartCounter} = useContext(HeaderContext);
    // const itemRef = useCallback((node) => {
    //     if (!node) return;
    //     const divs = node.querySelectorAll('.template-item')
    //     gsap.to(divs, {
    //         autoAlpha:1,
    //         y:0,
    //         stagger: 0.3,
    //         overwrite:true,
    //         duration: .3
    //     });
    // },[]);
    const [datas,setDatas] = useState(null);
    const [queryParam,setQueryParam] = useState({
        page:1,
        sortBy:'id',
        userId:currentUser.id
    });
    // const getData = async() => {
    //     const result = makeRequest.get('wishlists/pagination?'+queryString.stringify(queryParam));
    //     return result.data;
    // }
    // const queryClient = useQueryClient();
    // const { isLoading, error, data } = useQuery({
    //     queryKey:["wishlists"],
    //     queryFn: getData
    // });
    // if (isLoading) {
    //     return <span>Loading...</span>
    // }
    const initDatas = async() => {
        try {
            const result = await makeRequest.get('wishlists/pagination?'+queryString.stringify(queryParam));
            if(result.status===200){
                setDatas(result.data);
            }
        } catch (error) {
            console.log('get wishlist error: ',error)
            setDatas(null);
        }
    }
    const addToBuy = async(e,product) => {
        e.preventDefault();
        await showLoader();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            let colorObj = await makeRequest.get('product-colors/find-by-product-id?productId='+product.productId);
            let dto = {
                total:parseInt(product.price),
                status:'UNPAID',
                products:[{
                    productColorId:colorObj.data.data[0].id,
                    productId: product.productId,
                    quantity: 1,
                    price: parseInt(product.price)
                }],
                weight:Math.round(parseInt(product.weight)/1000)
            }

            //save to order
            try {
                const response = await makeRequest.post('transactions/orders',dto);
                navigate("/pembayaran/"+response.data.data.id);
            } catch (error) {
                alert(error.response.data.message);
                return;
            }
        }
        await hideLoader();
    }
    const addToCart = async(product) => {
        if(!currentUser){
            setLoginClicked(true);
        } else {
            const productColors = await makeRequest.get('product-colors/find-by-product-id?productId='+product.productId);
            try {
                const dto = {
                    productId:product.productId,
                    quantity:1,
                    price:parseInt(product.price),
                    productColorId:productColors.data.data[0].id
                }
                await makeRequest.post('carts/add-to-cart',dto);
                callToastNotification('success','cart',null);
            } catch (error) {
                callToastNotification('error','cart',null);
            }
        }
        await checkCartCounter();
    }
    const removeFromWishlist = async(e,data) => {
        e.preventDefault();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            try {
                await makeRequest.delete('wishlists?userId='+currentUser.id+'&productId='+data.productId);
                initDatas();
                callToastNotification('success','wishlist','remove');
            } catch (error) {
                callToastNotification('error','wishlist','remove');
            }
        }
    }
    const prev = () => {
        let currentPage = queryParam.page-1;
        setQueryParam(previous=>({...previous,page:currentPage}));
    }
    const next = () => {
        let currentPage = queryParam.page+1;
        setQueryParam(previous=>({...previous,page:currentPage}));
    }
    const goTo = (e,value) => {
        setQueryParam(previous=>({...previous,page:value}));
    }
    const ButtonToCart = () => {
        return (
            <a role="button" onClick={()=>onChange('keranjang')}>Barang berhasil ditambahkan ke keranjang. <strong>Klik disini untuk melihat keranjang.</strong></a>
        )
    }
    const ButtonSuccessAddWishlist = ({process}) => {
        return (
            <a role='button' onClick={()=>onChange('wishlist')}>Barang berhasil ditambahkan ke daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
        
    }
    const ButtonSuccessRemoveWishlist = ({process}) => {
        return (
            <a role='button' onClick={()=>onChange('wishlist')}>Barang berhasil dihapus dari daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
    }
    const callToastNotification = (status,type,process) => {
        if(type === 'cart'){
            if(status === 'success'){
                toast.success(<ButtonToCart/>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if(type==='wishlist'){
            if(status === 'success'){
                if(process==='add'){
                    toast.success(<ButtonSuccessAddWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(<ButtonSuccessRemoveWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    useEffect(()=>{
        initDatas();
    },[queryParam.page]);
  return (
    <>
        <ToastContainer/>
        <div className="content-profile">
            {datas && datas.result.length>0 &&
                <div className="content-profile-list active">
                    <div className="text-center mb2">
                        <div className="bold-caption">Wishlist</div>
                    </div>
                    <div className="wishlist-row">
                        <div className="template-container">
                            {datas.result.map(data=>{
                                return (
                                    <div className="template-item" key={data.id}>
                                        <div className="template-card">
                                            {data.guaranteeId && <img src={garansiImg} className="garansi" alt='garansi'/>}
                                            {data.discountId && <img src={discountImg} className="sale" alt="sale" />}
                                            <div className="template-card-header">
                                                <img src={FOLDER_MEDIA_PATH+data.files.split(',')[0]} alt="productImage" />
                                            </div>
                                            <div className="template-card-product">
                                                <div className="top-product-info">
                                                    <div className="top-product-name">
                                                        <div className="brand">{data.brandName}</div>
                                                        <div className="name">{data.name}</div>
                                                    </div>
                                                </div>
                                                <div className="bottom-product-info">
                                                    <div className="bottom-product-price">Rp. {thousandSeparator(data.price)}</div>
                                                </div>
                                                <div className="inline-space bottom-utility-wrapper">
                                                    <button type="" className="btnNine btnNine-default" onClick={(e)=>addToBuy(e,data)}>Beli</button>
                                                    <a className="btnNine btn-utils-product" onClick={()=>addToCart(data)}>
                                                        <svg className="wish-cart" viewBox="0 0 260.293 260.293">
                                                            <g>
                                                                <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                                                                    c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                                                                    c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                                                                    C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                                                                    l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                                                                    V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                                                                    h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                                                                    h51.974L237.201,92.197z"/>
                                                                <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                                                                    c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                                                                    c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                                                                    C117.877,222.853,112.317,228.415,105.482,228.415z"/>
                                                                <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                                                                    c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                                                                    c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                                                                    C215.908,222.853,210.347,228.415,203.512,228.415z"/>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                    <a className="btnNine btn-utils-product" onClick={(e)=>removeFromWishlist(e,data)}>
                                                        <svg viewBox="0 0 1080 1080"><path d="M268,1080c-9.24-3.11-19-5.22-27.64-9.5-36.39-18-51.56-49-54.65-88.38-10.3-131.67-21.38-263.28-32.25-394.91-5.23-63.36-10.67-126.71-15.95-190.07-.32-3.83,0-7.71,0-12.36h806c-1.87,23.49-3.65,46.8-5.58,70.11Q929.9,550.44,921.89,646q-9.17,110.27-18.31,220.53c-3.55,42.71-6.32,85.5-10.93,128.09-4.81,44.4-32.93,74-76.78,83.68a19.45,19.45,0,0,0-3.73,1.69ZM381.87,700.73q0-109.58,0-219.17c0-17-6.82-27.47-20.37-32.08-22.9-7.8-42.84,7.13-42.85,32.35q-.08,201.78,0,403.57c0,12.64-.26,25.3.12,37.93.42,14.13,8.26,24.11,21.29,28.13,22.47,6.93,41.77-7.43,41.82-31.55Q382,810.31,381.87,700.73Zm189.83-.15q0-109.58,0-219.17c0-16.91-6.89-27.4-20.51-32-22.94-7.71-42.7,7.23-42.71,32.54q-.08,212.32,0,424.64c0,6-.24,12,.15,17.91.87,13.45,8.67,23.09,21.4,27,22.5,6.85,41.63-7.53,41.67-31.73Q571.83,810.16,571.69,700.58Zm126.55-.29q0,16.34,0,32.68,0,93.3,0,186.59c0,16.75,7.08,27.29,20.82,31.76,22.67,7.38,42.37-7.28,42.39-31.93q.14-147.59,0-295.17c0-47.44-.07-94.88,0-142.32,0-13.85-5.06-24.7-17.63-31.1-21.73-11.08-45.57,4.61-45.63,30.22Q698.08,590.66,698.25,700.29Z"/><path d="M542.2,316.48q-214.55,0-429.1-.1A135.81,135.81,0,0,1,87,314c-12.56-2.49-20.7-10.65-20.89-23.17-.42-28.41-2-57.18,1.6-85.18,6-46.19,45.69-78.61,92.57-78.9q88.56-.55,177.12.11c8.4.06,12.43-2.09,14.84-10.38,5-17.17,10.9-34.08,16.8-51C383.17,25,417.45.3,460.14.15q80.13-.29,160.25,0c41.64.17,76.12,24.59,90.2,63.78q9.6,26.74,17.88,53.95c2.11,7,5.37,9,12.61,9,57.63-.34,115.27-.22,172.9-.18,60.3,0,100.52,40.16,100.63,100.31q0,26.88,0,53.77c-.09,23.16-12.66,35.71-35.94,35.72Q760.44,316.51,542.2,316.48ZM415.39,126H664.73c-5-14.8-9.4-28.84-14.63-42.55-5-13.2-15.47-19.89-29.41-19.94q-80.64-.3-161.28,0c-14,.05-24.38,6.79-29.43,19.94C424.72,97.11,420.41,111.17,415.39,126Z"/></svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="pagination-cover">
                            <ul className="pagination">
                                {queryParam.page > 1 &&
                                    <li className="pagination-item item-prev" onClick={prev}>
                                        <a>
                                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                                {Array.from({length: datas.totalPage}, (_,i)=>{
                                    return (
                                        <li key={i+1} className={`pagination-item ${queryParam.page === (i+1) ? "active" : ""}`}>
                                            <a onClick={(e)=>goTo(e,i+1)}>{i+1}</a>
                                        </li>
                                    )
                                })}
                                {queryParam.page < datas.totalPage &&
                                    <li className="pagination-item item-next" onClick={next}>
                                        <a>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {datas && datas.result.length === 0 &&
                <div className="content-profile-list active">
                    <div className="notif-content-profile">
                        <div className="caption-notif">
                            <div className="notif-message">Produk tidak ditemukan.</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
  )
}

export default UserWishlist