import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FOLDER_MEDIA_PATH } from '../utils';

const Contact = () => {
    const [currentState,setCurrentState] = useState('contact');
    const handleClick = (e,value) => {
        e.preventDefault();
        if(value===currentState){
            setCurrentState('');
        }else{
            setCurrentState(value);
        }
    }
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Kontak</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li>Kontak</li>
                </ul>
            </div>
        </div>

        <div className="section section-guarantee guarantee-wrapper">
            <div className="container">
                <div className="carousel-wrapper">
                    <div className={currentState==="privacyPolicy" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'privacyPolicy')}>Privacy Policy</div>
                        <div className={currentState==="privacyPolicy" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p>Privasi Anda penting bagi kami. Untuk melindungi privasi Anda dengan lebih baik, kami memberikan pemberitahuan ini yang menjelaskan praktik informasi online kami dan pilihan yang dapat Anda buat tentang cara informasi Anda dikumpulkan dan digunakan.</p>
                            <p>Di beberapa halaman, Anda dapat memasukkan alamat email Anda (untuk mendaftar akun anggota). Kami menggunakan email yang Anda berikan untuk pemberitahuan tanggapan atas pesan yang Anda posting di ulasan. Kami tidak akan pernah menggunakan atau membagikan alamat email yang diberikan kepada kami secara online dengan cara yang tidak terkait dengan yang dijelaskan di atas. Situs web <strong>NINE AUTOSERIES</strong> tidak akan membagikan informasi pribadi apa pun dengan pihak ketiga.</p>
                            <p className="mb0 semibold">Bagaimana kami menggunakan cookie.</p>
                            <p>Cookie adalah file teks yang sangat kecil yang disimpan di komputer Anda saat Anda mengunjungi beberapa situs web.</p>
                            <p>Kami menggunakan cookie untuk membantu mengidentifikasi komputer Anda sehingga kami dapat menyesuaikan pengalaman pengguna Anda, mengingat bahwa Anda telah masuk, dan melacak halaman yang Anda kunjungi melalui Google Analytics.</p>
                            <p>Kami menggunakan perusahaan periklanan pihak ketiga untuk menayangkan iklan ketika Anda mengunjungi situs web kami Perusahaan-perusahaan ini dapat menggunakan informasi (tidak termasuk nama, alamat, alamat email, atau nomor telepon Anda) tentang kunjungan Anda ke situs ini dan situs web lain untuk menyediakan iklan tentang barang dan jasa yang menarik bagi Anda.</p>
                            <p>Keamanan data – data pribadi anda, kami menjamin akan aman bersama kami. Permintaan data-data anda hanya diperuntukan untuk kebutuhan berbelanja dan memberikan ulasan tentang produk-produk kami, <strong>NINE AUTOSERIES</strong>.</p>
                        </div>
                    </div>
                    <div className={currentState==="legalInfo" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'legalInfo')}>Legal Info</div>
                        <div className={currentState==="legalInfo" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p><strong>NINE AUTOSERIES</strong> senang Anda mengunjungi situs ini dan kami senang memberikan informasi di website ini. Kami menganjurkan Anda untuk mencetak salinan informasi untuk penggunaan pribadi Anda, menyimpan file di komputer Anda, dan membuat tautan dengan website ini dari dokumen Anda sendiri. Namun, kami perlu memberi tahu Anda tentang beberapa batasan dan pembatasan hukum yang kami terapkan kepada semua pengunjung situs ini dan situs terkait.</p>
                            <p>Kami meminta Anda menghormati peraturan yang dijelaskan di bawah ini. Situs ini berisi informasi yang merupakan hak milik <strong>NINE AUTOSERIES</strong>. Kami telah menginvestasikan uang, waktu, dan tenaga untuk mengembangkannya. Properti tersebut termasuk namun tidak terbatas pada hak cipta, merek dagang, dan informasi tentang teknologi. Ini disediakan dalam bentuk teks, grafik, audio, video, unduhan, tautan, atau kode sumber (“Layanan dan Materi”). </p>
                            <p><strong>NINE AUTOSERIES</strong> memegang seluruh hak atas Layanan dan Materi tersebut. Layanan dan Materi ditujukan hanya untuk pelanggan kami dan disediakan hanya untuk kenyamanan Anda. <strong>NINE AUTOSERIES</strong> tidak memberi Anda lisensi atau hak milik atas Layanan dan Materi tersebut. Kami tidak menjamin keakuratan, kelengkapan, atau keandalan Layanan dan Materi serta item lain yang terdapat di server ini atau server lainnya. Oleh karena itu, Anda tidak boleh mengandalkan Layanan dan Materi apa pun yang disediakan di situs ini kecuali kami secara tegas memberi tahu Anda secara tertulis bahwa Anda dapat mengandalkannya.</p>
                            <p>Harap jangan mengandalkan atau memanfaatkan Layanan dan Materi untuk membeli, menjual, memperdagangkan, atau bertransaksi sekuritas apa pun. Perlu diperhatikan bahwa informasi yang diberikan di sini tidak memenuhi persyaratan pengungkapan badan pengatur seperti yang mungkin diwajibkan di berbagai negara. Dan jangan membuat keluhan dan klaim garansi apa pun yang tidak berdasarkan Layanan dan Materi yang disediakan di sini.</p>
                            <p><strong>LAYANAN DAN MATERI DISEDIAKAN OLEH NINE AUTOSERIES SEBAGAIMANA ADANYA, DAN KAMI SECARA TEGAS MENOLAK SETIAP KELUHAN DAN KLAIM GARANSI YANG TIDAK SESUAI DENGAN KETENTUAN YANG SUDAH DITENTUKAN. JIKA ADA KELUHAN SEHUBUNGAN DENGAN LAYANAN ATAU MATERI APAPUN. DALAM KEADAAN APA PUN, JIKA TIDAK SESUAI DENGAN GARANSI YANG DIBERIKAN NINE AUTOSERIES TIDAK BERTANGGUNG JAWAB ATAS KERUSAKAN LANGSUNG, TIDAK LANGSUNG, INSIDENTAL, HUKUMAN, ATAU KONSEKUENSIAL DALAM BENTUK APA PUN TERKAIT DENGAN LAYANAN DAN MATERI</strong>.</p>
                            <p>Informasi mengenai produk dan informasi apa pun yang terdapat dalam situs website ini merupakan sebuah penawaran oleh <strong>NINE AUTOSERIES</strong> terkait penjualan produk dan layanan, serta media edukasi tentang produk dan bidang otomotif lainnnnya. Meskipun Anda dapat dengan bebas mengunduh Layanan dan Materi dari situs ini dan situs terkait, <strong>NINE AUTOSERIES</strong> memegang semua hak merek dagang terhadap produk-produk yang ada di website ini berupa foto dan video produk.</p>
                            <p>Saat menggunakan situs website, Anda tidak boleh menggunakan atau mengakses situs websit kami dengan cara apa pun yang dapat merusak, menonaktifkan, membebani secara berlebihan, melemahkan, atau membahayakan sistem atau keamanan kami, atau mengganggu pengguna situs websit kami yang lain. Kecuali diizinkan oleh <strong>NINE AUTOSERIES</strong>, Anda tidak boleh menyalahgunakan informasi produk ekstraksi serupa atau metode otomatis lainnya, informasi atau data apa pun dari website, marketplace, dan atau server kami, atau sistem yang mencoba menguraikan transmisi apa pun ke atau dari server yang menjalankan situs website kami.</p>
                        </div>
                    </div>
                    <div className={currentState==="aboutUs" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'aboutUs')}>Tentang Kami</div>
                        <div className={currentState==="aboutUs" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p><strong>NINE AUTOSERIES</strong> adalah sektor bisnis yang bergerak dibidang otomotif diantara menghadirkan produk-produk seperti lampu, klakson, dan aksesoris lainnya yang dapat memenuhi kebutuhan Anda di bidang otomotif. Produk yang mengutamakan kualitas agar dapat diterima di Masyarakat.</p>
                            <p>NINE AUTOSERIES memiliki brand yang dapat dikenal yaitu NINE, dan diikuti dengan sub-brand yang sesuai dengan kategori produk itu sendiiri diantaranya :</p>
                            <ol>
                                <li>LUMINOS</li>
                                <li>LUXIMOS</li>
                                <li>9POWER</li>
                                <li>LX-TRIX</li>
                                <li>SECURICLE</li>
                                <li>SOUNDBLAX</li>
                                <li>GLOWCARE</li>
                                <li>CARPET</li>
                            </ol>
                            <p><strong>NINE AUTOSERIES</strong> berkomitmen menghadirkan produk aksesoris dan produk premium untuk industri otomotif. Mulai dari penerangan, perlindungan dan perawatan mobil dan sepeda motor Anda. Selain menjadikan tampilan kendaraan lebih baik, bersih dan modis. Kami juga berkomitmen terhadap kualitas dan memberikan layanan terbaik dengan standar tertinggi, produknya dapat ditemukan di market online ataupun offline.</p>
                            <p><strong>NINE AUTOSERIES</strong> juga akan mengulas produk-produk baru dan memberikan tips & trik seputar industri otomotif melalui social media Instagram, Youtube, Facebook, Tiktok. Kami berterima kasih atas pihak-pihak yang mendukung dan setia memakai produk-produk kami.</p>
                        </div>
                    </div>
                    <div className={currentState==="contact" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'contact')}>Kontak</div>
                        <div className={currentState==="contact" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p style={{paddingBottom:"0"}}><strong>PT. SEMBILAN AUTOSERIES INDONESIA</strong></p>
                            <p>Jl. Utama Sakti VIII No. 12 H&amp;I, Wijaya Kusuma <br/> Grogol Petamburan Jakarta Barat, Indonesia 11460 <br/> Telp : 021 569 424 69</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact