import '../sass/notmain.scss';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigation = useNavigate();
    const handleClick = () => {
        navigation("/");
    }
  return (
    <div className="not-found">
        <img src="https://i.imgur.com/qIufhof.png" />
        <div className="info">
            <h3>Halaman ini tidak ditemukan!</h3>
        </div>
        <button onClick={handleClick}>KE BERANDA</button>
    </div >
  )
}

export default NotFound