import { useEffect, useState } from 'react';
import { convertDate, FOLDER_MEDIA_PATH } from '../utils';
import { makeRequest } from '../axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/authContext';

const OrderStatus = () => {
    const formatDateWithTime = "dd-MM-yyyy H:mm";
    const {currentUser} = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const orderId = searchParams.get("orderId");
    const [data,setData] = useState([]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('orders/find-order?orderId='+orderId);
            const response = result.data.data;
            if(response.status==='UNPAID'){
                navigate('/pembayaran/'+response.id);
            } else {
                setData(result.data.data);
            }
        } catch (error) {
            setData([]);
        }
    }
    useEffect(()=>{
        if(!currentUser){
            navigate("/");
        }
        initData();
    },[])
  return (
    <div className="section-profile profile-wrapper">
        <div className="container">
            <div className="content-profile checkout-profile">
                {data?.status === 'COMPLETED' ? (
                    <div className="content-profile-list active">
                        <div className="text-center mb2">
                            <div className="bold-caption">Selesai</div>
                        </div>
                        <div className="inline-space-start">
                            <div className="column-product-cart" style={{width:'100%'}}>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Informasi Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>Jasa Pengiriman</div>
                                        <div className="text-right">Regular - {data?.order_shipment?.shipment?.name}</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb2">
                                    <div className="green semibold">Alamat Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout">
                                    <div className="address-box-checkout">
                                        <div className="flex" style={{alignItems:'center'}}>
                                            <div className="address-name"><span>{data?.order_shipment?.user_address?.addressReceiverName}</span> ({data.order_shipment.user_address.type})</div>
                                            {data?.order_shipment?.user_address?.isMain && <div className="address-main">Utama</div>}
                                        </div>
                                        <div className="address-phone">{data?.order_shipment?.user_address?.addressPhoneNumber}</div>
                                        <p className="address-desc">{data?.order_shipment?.user_address?.addressDetails} </p>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Produk Pesanan</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>No. Pesanan</div>
                                        <div className="text-right">{data?.id}</div>
                                    </div>
                                    <div className="inline-space">
                                        <div>Waktu Pemesanan</div>
                                        <div className="text-right">{convertDate(formatDateWithTime,data?.createdAt)}</div>
                                    </div>
                                </div>
                                {data?.order_items.map(item=>(
                                    <div className="bbgrey-checkout" key={item.id}>
                                        <div className="product-display-checkout">
                                            <div className="checkout-product-foto">
                                                <img src={FOLDER_MEDIA_PATH+item?.product?.product_colors[0].product_color_images[0].file.name} alt="productImage" />
                                            </div>
                                            <div className="checkout-product-desc">
                                                <div className="checkout-brand">{item?.product?.brand?.name}</div>
                                                <div className="checkout-name">{item?.product?.name}</div>
                                                <div className="checkout-color">Warna : <span>{item?.product?.product_colors[0].color.name}</span></div>
                                                <div className="checkout-weight">{item?.product?.weight}gr</div>
                                                <div className="checkout-quantity">{item?.quantity}</div>
                                                <div className="checkout-price">Rp {item?.product?.price*item?.quantity},-</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div className="semibold">Subtotal</div>
                                        <div className="semibold text-right">Rp {data?.total},-</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Metode Pembayaran</div>
                                </div>
                                <div className="mb1">
                                    <div>Transfer Via ({data?.paymentMethod})</div>
                                    <div>
                                        {data?.paymentMethod ==='BCA' && <img src={FOLDER_MEDIA_PATH+"bca.png"} style={{width:'100px'}} alt="bankImage" />}
                                    </div>
                                </div>
                                <div className="clearfix pv1">
                                    <div className="pull-right">
                                        <div className="button-address-wrapper">
                                            <button type="" className="btnNine-address-checkout">Lihat detail produk</button>
                                        </div>
                                    </div>
                                    <div className="pull-right mr1">
                                        <button type="" className="btnNine btnNine-default btnNIne-custom">Beli lagi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : data?.status === 'PAID' || data?.status === 'CONFIRMED' ? (
                    <div className="content-profile-list active">
                        <div className="text-center mb2">
                            <div className="bold-caption">Sedang Diproses</div>
                        </div>
                        <div className="inline-space-start">
                            <div className="column-product-cart" style={{width:'100%'}}>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Informasi Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>Jasa Pengiriman</div>
                                        <div className="text-right">Regular - {data?.order_shipment?.shipment?.name}</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb2">
                                    <div className="green semibold">Alamat Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout">
                                    <div className="address-box-checkout">
                                        <div className="flex" style={{alignItems:'center'}}>
                                            <div className="address-name"><span>{data?.order_shipment?.user_address?.addressReceiverName}</span> ({data.order_shipment.user_address.type})</div>
                                            {data?.order_shipment?.user_address?.isMain && <div className="address-main">Utama</div>}
                                        </div>
                                        <div className="address-phone">{data?.order_shipment?.user_address?.addressPhoneNumber}</div>
                                        <p className="address-desc">{data?.order_shipment?.user_address?.addressDetails} </p>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Produk Pesanan</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>No. Pesanan</div>
                                        <div className="text-right">{data?.id}</div>
                                    </div>
                                    <div className="inline-space">
                                        <div>Waktu Pemesanan</div>
                                        <div className="text-right">{convertDate(formatDateWithTime,data?.createdAt)}</div>
                                    </div>
                                </div>
                                {data.order_items.map(item=>(
                                    <div className="bbgrey-checkout" key={item.id}>
                                        <div className="product-display-checkout">
                                            <div className="checkout-product-foto">
                                                <img src={FOLDER_MEDIA_PATH+item?.product?.product_colors[0].product_color_images[0].file.name} alt="productImage" />
                                            </div>
                                            <div className="checkout-product-desc">
                                                <div className="checkout-brand">{item?.product?.brand?.name}</div>
                                                <div className="checkout-name">{item?.product?.name}</div>
                                                <div className="checkout-color">Warna : <span>{item?.product?.product_colors[0].color.name}</span></div>
                                                <div className="checkout-weight">{item?.product?.weight}gr</div>
                                                <div className="checkout-quantity">{item?.quantity}</div>
                                                <div className="checkout-price">Rp {item?.product?.price*item?.quantity},-</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div className="semibold">Subtotal</div>
                                        <div className="semibold text-right">Rp {data?.total},-</div>
                                    </div>
                                    <div className="inline-space">
                                        <div className="semibold">Ongkos Kirim</div>
                                        <div className="semibold text-right">Rp {data?.order_shipment.price},-</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Metode Pembayaran</div>
                                </div>
                                {(data?.paymentMethod === 'credit_card' || data?.paymentMethod === 'bank_transfer' || data?.paymentMethod === 'echannel') ?
                                    (
                                        <div className="mb1">
                                            <div>Transfer Via ({data?.bank.toUpperCase()})</div>
                                            <div>
                                                {data?.bank ==='bca' && <img src={FOLDER_MEDIA_PATH+"bca.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.bank ==='bni' && <img src={FOLDER_MEDIA_PATH+"bni.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.bank ==='mandiri' && <img src={FOLDER_MEDIA_PATH+"mandiri.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.bank ==='permata' && <img src={FOLDER_MEDIA_PATH+"permata.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.bank ==='bri' && <img src={FOLDER_MEDIA_PATH+"BRI.png"} style={{width:'100px'}} alt="bankImage" />}
                                            </div>
                                        </div>
                                    ) : data?.paymentMethod === 'cstore' ?
                                    (
                                        <div className="mb1">
                                            <div>Transfer Via ({data?.store})</div>
                                            <div>
                                                {data?.store ==='alfamart' && <img src={FOLDER_MEDIA_PATH+"alfamart.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.store ==='indomaret' && <img src={FOLDER_MEDIA_PATH+"indomaret.png"} style={{width:'100px'}} alt="bankImage" />}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb1">
                                            <div>Transfer Via ({data?.paymentMethod.toUpperCase()})</div>
                                            <div>
                                                {data?.paymentMethod ==='qris' && <img src={FOLDER_MEDIA_PATH+"qris.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.paymentMethod ==='gopay' && <img src={FOLDER_MEDIA_PATH+"GoPay.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.paymentMethod ==='shopeepay' && <img src={FOLDER_MEDIA_PATH+"ShopeePay.png"} style={{width:'100px'}} alt="bankImage" />}
                                                {data?.paymentMethod ==='akulaku' && <img src={FOLDER_MEDIA_PATH+"AkuLaku.png"} style={{width:'100px'}} alt="bankImage" />}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ) : data?.status === 'SENT' ? (
                    <div className="content-profile-list active">
                        <div className="text-center mb2">
                            <div className="bold-caption">Sedang Dikirim</div>
                        </div>
                        <div className="inline-space-start">
                            <div className="column-product-cart" style={{width:'100%'}}>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Informasi Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>Jasa Pengiriman</div>
                                        <div className="text-right">Regular - {data?.order_shipment?.shipment?.name}</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb2">
                                    <div className="green semibold">Alamat Pengiriman</div>
                                </div>
                                <div className="bbgrey-checkout">
                                    <div className="address-box-checkout">
                                        <div className="flex" style={{alignItems:'center'}}>
                                            <div className="address-name"><span>{data?.order_shipment?.user_address?.addressReceiverName}</span> ({data.order_shipment.user_address.type})</div>
                                            {data?.order_shipment?.user_address?.isMain && <div className="address-main">Utama</div>}
                                        </div>
                                        <div className="address-phone">{data?.order_shipment?.user_address?.addressPhoneNumber}</div>
                                        <p className="address-desc">{data?.order_shipment?.user_address?.addressDetails} </p>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Produk Pesanan</div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div>No. Pesanan</div>
                                        <div className="text-right">{data?.id}</div>
                                    </div>
                                    <div className="inline-space">
                                        <div>Waktu Pemesanan</div>
                                        <div className="text-right">{convertDate(formatDateWithTime,data?.createdAt)}</div>
                                    </div>
                                </div>
                                {data?.order_items.map(item=>(
                                    <div className="bbgrey-checkout" key={item.id}>
                                        <div className="product-display-checkout">
                                            <div className="checkout-product-foto">
                                                <img src={FOLDER_MEDIA_PATH+item?.product?.product_colors[0].product_color_images[0].file.name} alt="productImage" />
                                            </div>
                                            <div className="checkout-product-desc">
                                                <div className="checkout-brand">{item?.product?.brand?.name}</div>
                                                <div className="checkout-name">{item?.product?.name}</div>
                                                <div className="checkout-color">Warna : <span>{item?.product?.product_colors[0].color.name}</span></div>
                                                <div className="checkout-weight">{item?.product?.weight}gr</div>
                                                <div className="checkout-quantity">{item?.quantity}</div>
                                                <div className="checkout-price">Rp {item?.product?.price*item?.quantity},-</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="bbgrey-checkout mb1">
                                    <div className="inline-space">
                                        <div className="semibold">Subtotal</div>
                                        <div className="semibold text-right">Rp {data.total},-</div>
                                    </div>
                                </div>
                                <div className="bbgrey-checkout mb1">
                                    <div className="green semibold">Metode Pembayaran</div>
                                </div>
                                <div className="mb1">
                                    <div>Transfer Via ({data?.paymentMethod})</div>
                                    <div>
                                        {data?.paymentMethod ==='BCA' && <img src={FOLDER_MEDIA_PATH+"bca.png"} style={{width:'100px'}} alt="bankImage" />}
                                    </div>
                                </div>
                                <div className="clearfix pv1">
                                    <div className="pull-right">
                                        <div className="button-address-wrapper">
                                            <button type="" className="btnNine-address-checkout">Lihat detail produk</button>
                                        </div>
                                    </div>
                                    <div className="pull-right mr1">
                                        <button type="" className="btnNine btnNine-default btnNIne-custom">Produk Diterima</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):(<div>Nomor Pesanan Tidak Ditemukan!</div>)}
            </div>
        </div>
    </div>
  )
}

export default OrderStatus