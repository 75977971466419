import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCallback, useEffect, useState } from "react";
import {FOLDER_MEDIA_PATH} from '../utils.js';
import { makeRequest } from "../axios.js";

const Brosur = () => {
    gsap.registerPlugin(ScrollTrigger);
    const [datas,setDatas] = useState([]);

    const brochureRef = useCallback((node) => {
        if (!node) return;
        const divs = node.querySelectorAll('.template-item')
        gsap.to(divs, {
            autoAlpha:1,
            y:0,
            stagger: 0.3,
            overwrite:true,
            duration: .6
        });
    },[]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('downloads/find-by-type?type=brosur');
            setDatas(result.data.data);
        } catch (error) {
            setDatas([]);
        }
    }
    useEffect(()=>{
        initData();
    },[]);
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner Downloads.jpg"} alt="newsBannerImage"/>
            <div className="content-container">
                <h1>Brosur</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/"><span>Beranda</span></Link></li>
                    <li>Brosur</li>
                </ul>
            </div>
        </div>

        <div className="section section-brochure brochure-wrapper">
            <div className="container">
                <div className="template-container" ref={brochureRef}>
                    {datas?.map(data=>(
                        <div key={data.id} className="template-item" onClick={()=>window.open(FOLDER_MEDIA_PATH+data.file.name,'_blank')}>
                            <div className="template-card">
                                <div className="template-card-header">
                                    <img src={FOLDER_MEDIA_PATH+data.image.name} alt="brochure"/>
                                </div>
                                <div className="template-card-desc">
                                    <div className="template-product-name" data-text="Brosur">{data.type}</div>
                                    <p className="template-product-desc">{data.name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default Brosur