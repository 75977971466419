import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FOLDER_MEDIA_PATH } from '../utils';

const Guarantee = () => {
    const [currentState,setCurrentState] = useState('snk');
    const handleClick = (e,value) => {
        e.preventDefault();
        if(value===currentState){
            setCurrentState('');
        }else{
            setCurrentState(value);
        }
    }
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner Garansi.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Garansi</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li>Garansi</li>
                </ul>
            </div>
        </div>

        <div className="section section-guarantee guarantee-wrapper">
            <div className="container">
                <div className="carousel-wrapper">
                    <div className={currentState==="snk" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'snk')}>Syarat dan Ketentuan</div>
                        <div className={currentState==="snk" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p className="pv15px">Ini merupakan informasi penting tentang garansi yang akan Anda dapatkan dari produk yang bertanda garansi. Produk-produk yang terdapat di market online atau offline tidak semua memiliki garansi. Pastikan produk yang telah Anda beli wajib didaftarkan di website ini untuk mengaktifkan status garansi. Setiap produk memiliki masa garansi yang sudah ditentukan, dan masa garansi menyesuaikan pada waktu Anda mendaftarkannya.</p>
                            <p>Klaim garansi dapat dilakukan setelah mendaftarkan produk Anda di website ini. Klaim garansi tidak dapat Anda lakukan jika masa garansi telah habis. Disarankan segera mendaftarkan produk Anda setelah melakukan transaksi pembelian.</p>
                            <p>Produk yang telah Anda daftarkan akan otomatis masuk kedalam daftar produk yang ada di profile Anda. Jika Anda melakukan klaim garansi, sistem akan meminta Anda memasukan data - data yang dibutuhkan untuk dapat diproses selanjutnya. Pastikan Anda memasukan data identitas profil (nama, alamat, telepon) dengan lengkap dan benar.</p>
                        </div>
                    </div>
                    <div className={currentState==="cekStatus" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'cekStatus')}>Cek Status Garansi Produk Anda</div>
                        <div className={currentState==="cekStatus" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p className="pv15px">Ini merupakan alat verifikasi garansi yang akan menunjukkan status garansi produk original dari kami. Berdasarkan tanggal pendaftaran produk. Jika produk yang ditemukan dari pencarian tidak muncul Anda akan diarahkan di halaman profile untuk mendaftarkan produk Anda terlebih dahulu.</p>
                            <div className="flex-body-guarantee">
                                <div className="form-group">
                                    <input type="text" name="Kode Produk" id="Kode Produk" className="form-control" placeholder="Kode Produk" />
                                </div>
                                <a className="btnNine btnNine-default" href="#">Check</a>
                            </div>
                            <div className="row-product-garansi">
                                <div className="photo-product-garansi">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-product-garansi">
                                    <div className="product-name">SHL - Q1</div>
                                    <div className="product-code">NN-LLED-SHL-Q1</div>
                                    <div className="product-garansi">Garansi : <span>Dalam masa garansi sampai 30-09-2023</span></div>
                                </div>
                            </div>
                            <div className="row-product-garansi">
                                <div className="photo-product-garansi">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-product-garansi">
                                    <div className="product-name">SHL - Q1</div>
                                    <div className="product-code">NN-LLED-SHL-Q1</div>
                                    <div className="product-garansi">Garansi : <span className="red">Kadaluarsa pada 30-09-2023</span></div>
                                </div>
                            </div>
                            <div className="notif-garansi-period">Maaf! Produk yang anda cari tidak ditemukan. <br/>Daftarkan produk Anda pada menu halaman profile!</div>
                        </div>
                    </div>
                    <div className={currentState==="guaranteeClaim" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClick(e,'guaranteeClaim')}>Klaim Garansi</div>
                        <div className={currentState==="guaranteeClaim" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p>Ini merupakan alat verifikasi klaim garansi yang akan membantu Anda atas keluhan produk kami. Silahkan ikuti langkah-langkah berikut kemudian klik tombol klaim. Pastikan data-data dan keluhan Anda ditulis dengan lengkap dan benar. Sistem akan memberikan konfirmasi produk, respon dan notifikasi di halaman profile Anda. Pastikan produk Anda terdaftar dan mengikuti proses klaim sesuai ketentuan pada sistem.</p>
                            <a className="btnNine btnNine-default" href="#">Klaim Disini</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Guarantee