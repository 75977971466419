import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App.js';
import { AuthContextProvider } from './context/authContext';
import { HeaderContextProvider } from './context/headerContext.js';
import { LoadingProvider } from './context/LoadingContext.js';
import Loader from './components/Loader.jsx';
// import { QueryClient, QueryClientProvider } from 'react-query'

const root = ReactDOM.createRoot(document.getElementById('root'));
// const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>
  // <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <HeaderContextProvider>
        <LoadingProvider>
          <App />
          <Loader/>
        </LoadingProvider>
      </HeaderContextProvider>
    </AuthContextProvider>
  // </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
