import { createContext, useState } from "react";
import { makeRequest } from "../axios.js";

export const HeaderContext = createContext();

export const HeaderContextProvider = ({children}) => {
    const [cartCounter,setCartCounter] = useState(0);
    const [notificationCounter,setNotificationCounter] = useState(0);

    const checkCartCounter = async() => {
        try {
            const result = await makeRequest.get('carts/count',{withCredentials:true});
            setCartCounter(result.data.data);
        } catch (error) {
            setCartCounter(0);
        }
    }

    const checkNotificationCounter = async() => {
        try {
            const result = await makeRequest.get('notifications/count',{withCredentials:true});
            setNotificationCounter(result.data.data);
        } catch (error) {
            setNotificationCounter(0);
        }
    }

    return (
        <HeaderContext.Provider value={{cartCounter,notificationCounter,checkCartCounter,checkNotificationCounter}}>
            {children}
        </HeaderContext.Provider>
    )
}