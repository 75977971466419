import { useEffect, useState } from 'react';
import '../sass/notmain.scss';
import { Link } from 'react-router-dom';
import {makeRequest} from '../axios.js';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error,setError] = useState('');
  const [success,setSuccess] = useState(null);
  const [email,setEmail] = useState('');
  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }
  const handleSubmit = async(e) => {
    e.preventDefault();

    //check email
    try {
      await makeRequest.get('users/check-email?email='+email);
    } catch (error) {
      setError(error.response.data.message);
      return;
    }

    try {
      //send email verify account
      const emailDto = {
        from:process.env.REACT_APP_SMTP_EMAIL_FROM,
        to:email,
        subject:'Lupa Password '+email,
        type:'forgot-password'
      };
      await makeRequest.post('send-email',emailDto);

      setSuccess('Email berhasil dikirim, mohon untuk mengecek email anda untuk mereset ulang password anda.');
      setEmail('');
      setError(null);
      navigate('')
    } catch (error) {
      setError(error.response.data.message);
      setSuccess(null);
    }
  }
  useEffect(()=>{
  },[error]);
  return (
    <div className='forgot-password'>
      <div className="wrapper">
        <img src="https://cdn-icons-png.flaticon.com/512/6897/6897039.png" />
        <div className='title'>Lupa Password</div>
        <div className='detail'>Masukkan alamat email anda dan kami akan mengirimkan email agar Anda dapat melakukan pengaturan ulang pada password Anda.</div>
        <input id="email" name="email" className='input' onChange={handleChange}/>
        {error && <div className='error'>{error}</div>}
        {success && <div className='success'>{success}</div>}
        <button className='btn-submit' onClick={handleSubmit}>SUBMIT</button>
        <Link to="/" className='back-to-login'>Kembali ke Login</Link>
      </div>
    </div>
  )
}

export default ForgotPassword