import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Guarantee = () => {
    gsap.registerPlugin(ScrollTrigger);
    // var tl = gsap.timeline();
    // useEffect(()=>{
    //     tl
    //         .to('.info-banner',{
    //             scrollTrigger: {
    //                 trigger: '.info-banner'
    //             },
    //             duration: .6,
    //             y: 15,
    //             autoAlpha: 1,
    //             ease: "back.out(3)"
    //         })
    //         .to('.image-banner',{
    //             scrollTrigger: {
    //                 trigger: '.image-banner',
    //                 start: 'center 80%',
    //             },
    //             duration: 2,
    //             scale: 1,
    //             opacity: 1,
    //             ease: "back.out(2)"
    //         });
    // },[tl]);
    useEffect(() => {
        gsap.to('.info-banner',{
            scrollTrigger: {
                trigger: '.info-banner'
            },
            duration: .6,
            y: 15,
            autoAlpha: 1,
            ease: "back.out(3)"
        });
        gsap.to('.image-banner',{
            scrollTrigger: {
                trigger: '.image-banner',
                start: 'center 80%',
            },
            duration: 2,
            scale: 1,
            opacity: 1,
            ease: "back.out(2)"
        });
    });
  return (
    <>
        <div className="text-center title-wrapper">
            <h4>Claim Garansi</h4>
            <div className="sub-heading">Pastikan pembelian produk Anda original dari kami. </div>
        </div>
        <div className="section why-us">
            <div className="container">
                <div className="flex-container">
                    <div className="info-banner">
                        <div className="title-banner">Claim Garansi Anda di sini</div>
                        <p className="subtitle-banner">Untuk claim garansi pastikan Anda sudah terdaftar di situs resmi nine autoseries dan pastikan produk Anda sudah didaftarkan di website resmi kami. </p>
                        <a className="btnNine btnNine-default" href="product.html">Claim Here</a>
                    </div>
                    <div className="image-banner">
                        <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M786.75,1060.45a140.39,140.39,0,0,1-73.83-21L540,933.08,367.15,1039.46a140.8,140.8,0,0,1-210.67-153l47.76-197.3L49.6,557.59a140.82,140.82,0,0,1,80.48-247.65l202.41-15.55,77.32-187.7a140.82,140.82,0,0,1,260.38,0v0l77.32,187.7,202.41,15.55a140.82,140.82,0,0,1,80.48,247.65L875.76,689.12l47.76,197.3a140.94,140.94,0,0,1-136.78,174ZM540,846.09a89.64,89.64,0,0,1,46.88,13.32L782.57,979.83c20.6,12.74,38.19,3,44.72-1.72s21.11-18.38,15.43-42L788.67,712.85a89.17,89.17,0,0,1,28.86-89.13l175-148.92c18.52-15.76,14.72-35.36,12.2-43s-10.91-25.84-35.18-27.71l-229-17.59a89.05,89.05,0,0,1-75.81-55L577.19,119C567.93,96.51,548.11,94.07,540,94.07S512.07,96.51,502.81,119L415.3,331.37a89.16,89.16,0,0,1-75.81,55.1L110.42,404.05c-24.26,1.87-32.66,20-35.18,27.71s-6.32,27.28,12.2,43L262.4,623.66a89.31,89.31,0,0,1,29,89.09L237.27,936.11c-5.67,23.62,8.9,37.26,15.43,42s23.91,14.43,44.8,1.72L493.05,859.44A89.6,89.6,0,0,1,540,846.09Z"/></svg>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Guarantee