import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import {Link} from 'react-router-dom';
import { FOLDER_MEDIA_PATH } from "../utils";

const News = ({datas}) => {
    gsap.registerPlugin(ScrollTrigger);

    const newsRef = useCallback((node) => {
        if (!node) return;
        const divs = node.querySelectorAll('.news-item')
        gsap.to(divs, {
            autoAlpha:1,
            y:0,
            stagger: 0.3,
            overwrite:true,
            duration: .6
        });
    },[]);
  return (
    <>
        {datas.length>0 &&
            <div className="news">
                <div className="container">
                    <div className="news-container" ref={newsRef}>
                        <div className="news-item">
                            <div className="news-card">
                                <div className="bg-wrapper-news">
                                    <div className="caption white">Berita</div>
                                    <div className="caption white mb10px">Terkini</div>
                                    <p>Dapatkan informasi <br/>seputar produk dan aktivitas <br/> kami lainnya.</p>
                                    <Link to="/berita" className="btn-news-more">Lihat Semua Berita</Link>
                                </div>
                            </div>
                        </div>
                        {datas.map(data => {
                            return (
                            <div className="news-item" key={data.id}>
                                <div className="news-card">
                                    <div className="bg-wrapper-news">
                                        <div className="bg-news" style={{backgroundImage:`url("${FOLDER_MEDIA_PATH+data.pathImage}")`}}></div>
                                        <div className="detail-news">
                                            <p>{data.title}</p>
                                            <Link to={"/detail-berita/"+data.id} className="more">Lihat Selengkapnya</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default News