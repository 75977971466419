import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import {Link, useLocation} from 'react-router-dom';
import { FOLDER_MEDIA_PATH } from "../utils";
import { makeRequest } from "../axios";

const NewsCategory = () => {
    gsap.registerPlugin(ScrollTrigger);
    const location = useLocation();
    const [datas,setDatas] = useState([]);
    const [path,setPath] = useState(location.pathname.split('/')[3]);

    const newsRef = useCallback((node) => {
        if (!node) return;
        const divs = node.querySelectorAll('.news-item')
        gsap.to(divs, {
            autoAlpha:1,
            y:0,
            stagger: 0.3,
            overwrite:true,
            duration: .6
        });
    },[]);

    const initDatas = async() => {
        try {
            const result = await makeRequest.get('news/find-by-category?category='+path);
            setDatas(result.data.data);
        } catch (error) {
            setDatas([]);
        }
    }
    useEffect(()=>{
        initDatas();
    },[]);
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Berita</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li><Link to="/berita">Berita</Link></li>
                    <li>Kategori</li>
                </ul>
            </div>
        </div>
        {datas.length>0 ? (
            <div className="news">
                <div className="container">
                    <div className="news-container" ref={newsRef}>
                        {datas.map(data => {
                            return (
                            <div className="news-item" key={data.id}>
                                <div className="news-card">
                                    <div className="bg-wrapper-news">
                                        <div className="bg-news" style={{backgroundImage:`url("${FOLDER_MEDIA_PATH+data.pathImage}")`}}></div>
                                        <div className="detail-news">
                                            <p>{data.title}</p>
                                            <Link to={"/detail-berita/"+data.id} className="more">Lihat Selengkapnya</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            ) : (
                <div style={{minHeight:'450px',fontSize:'20px',padding:'20px'}}>
                    <span>Berita tidak ditemukan</span>
                </div>
            )
        }
    </>
  )
}

export default NewsCategory