import React from 'react';
import './Loader.css';
import { useLoading } from '../context/LoadingContext.js';

const Loader = () => {
  const { loading } = useLoading();

  return (
    <>
      {!loading ? null : (
        <div className="loader">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default Loader;